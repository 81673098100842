import { Comment } from '@ant-design/compatible';
import { Form, Input, message, Row, Col, Tabs, Pagination, Table, Select, Divider } from 'antd';
import { moment } from '../ui-elements/FormDate'
import React, { useState, FC, useEffect, ReactNode } from 'react';
import { dispatch, AsyncThunkActionType, CaseReducerType } from '../../../store/store'
import { adminMessagesGet } from '../../../store/kyc-backend/asyncThunks/adminMessagesGet'
import { ButtonUpper } from './Buttons'
import Well from './Well'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus, ResponseInfo } from '../../../hooks/useActionStatus'
import styled from 'styled-components'
import { themeColor } from '../../../styles/themeStyles'
import { camelCaseToName, nameParse } from '../../../utilities/nameParsers'
import FormSelect from './FormSelect'

const { TextArea } = Input;
const { TabPane } = Tabs;

const ButtonGroupItem = styled(ButtonUpper)`
    width: 100%;
`

const WellCustom = styled(Well)`
    background-color: ${themeColor.grayOpaque}
`

export const PreEl = styled.pre`
    display: 'block';
    padding: '10px 30px';
    margin: '0';
    overflow: 'scroll';
`

const CommentCustom = styled(Comment)`
    .ant-comment-inner {
        padding-bottom: 0;
    }
`

const CommentItemStyled = styled.div`
    padding: 15px 10px;
    margin-left: -10px;
    margin-right: -10px;
    border-bottom: 1px solid ${themeColor.grayWhite};
    h3 {
        font-size: 15px;
        overflow-x: hidden;
    }
    &.unread {
        font-weight: bold;
        background-color: ${themeColor.blueWhite}
    }
    &:last-child {
        border-bottom: 0;
    }
    .date {
        color: ${themeColor.gray};
        font-size: 12px;
        margin-bottom: 10px;
    }
`

const TabsCustom = styled(Tabs)`
    .ant-tabs-nav {
        margin-bottom: 0;
    }
    .ant-tabs-tab {
        padding-left: 15px;
        padding-right: 15px;
    }
    .ant-tabs-nav-list {
        width: 100%;
    }
    .ant-tabs-tab-disabled {
        flex-grow: 1;
        text-align: right;
        .ant-tabs-tab-btn {
            width: 100%;
        }
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${themeColor.primaryColor};
        text-shadow: 0 0 0.25px ${themeColor.primaryColor};
    }
    .ant-tabs-nav-operations {
        display: none!important;
    }
    .ant-tabs-ink-bar {
        background-color: ${themeColor.primaryColor};
    }
`

const TableCustom = styled(Table)`
    thead {
        //display: none;
    }
    .ant-table-row.unread {
        font-weight: bold;
        .ant-table-cell {
            background-color: #fafafa;
        }
    }
    .ant-table-cell {
        &.hidden {
            //display: none;
        }
    }
`

const InputCustom = styled(Input)`
    height: 40px
`

type MessageType = 'note' | 'message';
type SideType = 'client' | 'admin'
type PersonType = 'BUSINESS' | 'PRIVATE' | undefined;
type ActionType = AsyncThunkActionType<any, any>;
type ClearActionType = CaseReducerType<any, null>;

export interface CommentItem {
    key: string,
    id: string,
    author: string;
    adminAuthor?: string,
    subject: string;
    subjectShort: string;
    isRead?: boolean;
    isUserMessage?: boolean;
    isExpanded: boolean,
    handleRead?: any;
    content: React.ReactNode;
    contentShort: string;
    datetime: React.ReactNode;
    messageAccountId?: string;
    messageAccountType?: 'company' | 'individual'
}

interface EditorProps {
    onSubmit: () => void;
    submitting: boolean;
    type: MessageType,
    side?: SideType,
    writeTo?: any,
    msgPostBusiness?: ActionType,
    msgPostPrivate?: ActionType,
    adminInbox?: boolean
}

interface MessagesListingProps {
    type: MessageType, 
    side?: SideType,
    msgGet: ActionType,
    msgGetClear: ClearActionType,
    msgPostBusiness?: ActionType,
    msgPostPrivate?: ActionType,
    msgPostBusinessClear?: ClearActionType,
    msgPostPrivateClear?: ClearActionType,
    msgViewedPutBusiness?: ActionType,
    msgViewedPutPrivate?: ActionType,
    msgViewedPutBusinessClear?: ClearActionType,
    msgViewedPutPrivateClear?: ClearActionType,
    listing: any[] | {companyMessages: any[], individualMessages: any[]}, 
    wasViewedBusiness?: { id: string; read: number; },
    wasViewedPrivate?: { id: string; read: number; }, 
    msgElemSentBusiness?: any,
    msgElemSentPrivate?: any,
    adminInbox?: boolean
}

const columnsNotes = [
    {
        title: 'Author',
        dataIndex: 'author',
        key: 'author',
        fixed: 'left'
    },
    {
        title: 'Message',
        dataIndex: 'contentShort',
        key: 'contentShort'
    },{
        title: 'Date',
        dataIndex: 'datetime',
        key: 'datetime'
    },
    Table.EXPAND_COLUMN
];

const columnsUser = [
    {
        title: 'Subject',
        dataIndex: 'subjectShort',
        key: 'subjectShort',
        fixed: 'left',
    },
      {
        title: 'Message',
        dataIndex: 'contentShort',
        key: 'contentShort'
    },{
        title: 'Date',
        dataIndex: 'datetime',
        key: 'datetime'
    },
    Table.EXPAND_COLUMN
];



const columns = (received: boolean) => ([
    {
        title: 'Author',
        dataIndex: received ? 'author' : 'adminAuthor',
        key: 'author',
        fixed: 'left'
    },
    ...columnsUser
]);

const columnsSent = (received: boolean) => ([
    {
        title: 'To',
        dataIndex: 'author',
        key: 'author',
        fixed: 'left'
    },
    ...columns(received)
]);

export const CommentList = ({ comments, type, received, admin, userMessages, adminInbox, handleRead }: { comments: CommentItem[], received: boolean, type: MessageType, admin?: boolean, userMessages?: boolean, adminInbox?: boolean, handleRead: any }) => {
    let columnsRender;
    if(admin) {
        if(type === 'note') {
            columnsRender = columnsNotes
        } else {
            if(adminInbox) {
                if(received) {
                    columnsRender = columns(received)
                } else {
                    columnsRender = columnsSent(received)
                }
            } else {
                if(received) {
                    columnsRender = columnsUser
                } else {
                    columnsRender = columns(received)
                }
            }
        }
        
    } else {
        columnsRender = columnsUser
    }
    return ( 

        <TableCustom
            dataSource={comments}
            columns={columnsRender}
            rowClassName={(record, index) => {
                //@ts-ignore
                if (!record.isRead) {
                  return "unread";
                } else {
                    return ""
                }
            }}
            pagination={{ showSizeChanger: false }} 
            expandable={{
                expandedRowRender: comment => (
                    <Row justify="space-between" style={{padding: '10px 0'}}>
                        <Col>
                            {/*@ts-ignore*/}
                            <h4 style={{ maxWidth: 1200 }}>{comment.subject}</h4>
                            {/*@ts-ignore*/}
                            <div style={{ margin: 0, maxWidth: 1200, whiteSpace: "pre-line"}}>{comment.content}</div>
                        </Col>
                    </Row>
                ),
                expandIcon: ({ expanded, onExpand, record }) => {
                    const handleExpand = (record: object, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                        handleRead(record, expanded);
                        onExpand(record, e);
                    }
                    return (
                        <>
                        {expanded ? (
                            <ButtonUpper className='btn-sm' btnType='default' onClick={e => handleExpand(record, e)}>Close</ButtonUpper>
                        ) : (
                            <ButtonUpper className='btn-sm' btnType='default' onClick={e => handleExpand(record, e)}>Read</ButtonUpper>
                        )}
                        </>
                    )
                },
                //@ts-ignore
                rowExpandable: comment => comment.content !== undefined,
            }}
          />
    )
};

const Editor = ({ onSubmit, submitting, type, side, writeTo, msgPostBusiness, msgPostPrivate, adminInbox}: EditorProps) => {
    const [value, setValue] = useState('');
    const [subject, setSubject] = useState('');
    const [select, setSelect] = useState({id: '', type: ''});
    const [refreshSelect, setRefreshSelect] = useState(false);
    const envs = useSelector((state) => state.general.envs);
    const companies = useSelector((state) => state.admin.backCompany);
    const individuals = useSelector((state) => state.admin.backIndividual);
    const { Option } = Select;
    useEffect(() => {
        if(refreshSelect === true) {
            setRefreshSelect(false);
        }
       
    }, [refreshSelect]);
    const handleSubmit = () => {
        if (!value ) return;
        if(type === 'message') {
            if(envs.accountType === 'BUSINESS' || select.type === 'BUSINESS') {
                if(msgPostBusiness) dispatch(msgPostBusiness({params: {companyId: envs.profileId !== '' ? envs.profileId : select.id}, data: {subject: subject, message: value} }))
            } else {
                if(msgPostPrivate) dispatch(msgPostPrivate({params: {individualId: envs.profileId !== '' ? envs.profileId : select.id}, data: {subject: subject, message: value}}))
            }
        }
        if(type === 'note') {
            if(envs.accountType === 'BUSINESS') {
                if(msgPostBusiness) dispatch(msgPostBusiness({params: {companyId: envs.profileId}, data: {message: value} }));
            } else {
                if(msgPostPrivate) dispatch(msgPostPrivate({params: {individualId: envs.profileId}, data: {message: value} }));
            }
        }
        setValue('');
        setSubject('');
        setSelect({id: '', type: ''});
        setRefreshSelect(true);
        onSubmit();
    };
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };
    const handleRecipientChange = (el: string, e: any) => {
        setSelect({id: el, type: e.type});
    }
    const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubject(e.target.value);
    };
    return (
      <>
        {adminInbox &&
            <div style={{marginBottom: 15, marginTop: -15}}>
                <Divider />
                <h2>New message</h2>
                {refreshSelect === false &&
                    <FormSelect label='' defaultValue={select.id} type='company' id='recipient' placeholder='To' onChange={(val, e) => handleRecipientChange(val, e)} disabled={false} hideApprove={true}  >
                        {[...companies.profiles, ...individuals.individuals].map((user, i) => {
                            //@ts-ignore
                            const isCompany = user.companyId ? true : false;
                            //@ts-ignore
                            return <Option type={isCompany ? 'BUSINESS' : 'PRIVATE'} key={'user' + i} value={isCompany ? user.companyId : user.individualId}>{isCompany ? user.name ? user.name : user.companyId : user.profile.lastName && user.profile.firstName ? user.profile.firstName + ' ' + user.profile.lastName : user.individualId}</Option>
                            
                        })}
                    </FormSelect>
                }
            </div>
        }
        {type === 'message' &&
            <Form.Item>
                <InputCustom name='subject' placeholder='Subject' onChange={handleSubjectChange} value={subject} />
            </Form.Item>
        }
        <Form.Item>
            <TextArea name='text' rows={4} onChange={handleChange} value={value} />
        </Form.Item>
        <Form.Item style={{'marginBottom':"5px"}}>
            <ButtonUpper htmlType="submit" onClick={handleSubmit} btnType="primary">
                {side === 'client' ? (
                        <>Send message to Admin</>
                    ) : (
                        <>{type === 'note' ? 'Add Note' : 'Send message to Client'}</>
                    )
                }
            </ButtonUpper>
        </Form.Item>
      </>
    )
};

export const getSubject = (message: any) => {
    let subject = '';
    let messageEl: string | ReactNode = '';
    let messageExtract;
    let messageUpdateText = 'has been updated by Bitclear Admin. Please check it and if all is correct, submit whole KYC to us once again by clicking "Submit to us" on last step of KYC';
    if(message.subject.startsWith('update.') || message.subject.startsWith('update')) {
        if(message.subject.startsWith('update')) {
            subject = 'Bitclear Admin has updated Your profile'
            messageEl = 'Your profile ';
        } else {
            let subjectElems = message.subject.split('.');
            subjectElems.forEach((elem: any) => {
                switch(elem) {
                    case 'update':
                        subject = 'Bitclear Admin has updated '
                        break
                    case 'company':
                        subject += 'Company '
                        messageEl = 'Your company profile ';
                        break
                    case 'ubo': 
                        subject += 'UBO '
                        messageEl = 'UBO profile ';
                        break
                    case 'ap':
                        subject += 'Authorized Person '
                        messageEl = 'Authorized Person profile '
                        break
                    default:
                        subject += elem
                }
                
            })
        }
        
        messageEl += messageUpdateText;
        messageEl += message.message;
        messageExtract = messageEl;
    }  else if (message.subject === "Your KYC was updated, verify changes." || message.subject === 'Your KYC profile is updated, please verify changes in your profile.' || message.subject.startsWith('Beneficial Owner profile -') || message.subject.startsWith('Authorized person profile -')) {
        subject = message.subject
        messageEl = <><p>Your profile  {messageUpdateText}:</p><p><div style={{'marginLeft': 15}}><WellCustom>{getMessage(message.message)}</WellCustom></div></p></>;
        messageExtract = 'Your profile ' + messageUpdateText;
    } else {
        subject = message.subject
        messageEl = message.message
        messageExtract = messageEl;
    }
    let el = {
        subject: subject,
        messageEl: messageEl,
        messageExtract: messageExtract
    }
    return el
}

export const getMessage = (message: any) => {
    let jsonMessage = JSON.parse(message);
    let elems: ReactNode[] = [];
    Object.entries(jsonMessage).map((elem, i) => {
        let value = elem[1];
        if(typeof value !== "object") {
            if(value !== '') {
                elems.push(<p key={i}><span>{nameParse(elem[0])}: </span><b>{(typeof value === 'boolean') ? <>{String(value)}</> : <>{value}</>}</b></p>)
            }
        } else {
            //@ts-ignore
            Object.entries(value).map((elemInner, ind) => {
                let valueInner = elemInner[1];
                if(typeof valueInner !== "object") {
                    if(valueInner !== '') {
                        elems.push(<p key={i + ',' + ind}><span>{nameParse(elemInner[0])}: </span><b>{(typeof valueInner === 'boolean') ? <>{String(valueInner)}</> : <>{valueInner}</>}</b></p>)
                    }
                } else {
                    //@ts-ignore
                    Object.entries(valueInner).map((elemThird, ind2) => {
                        let valueThird = elemThird[1];
                        if(valueThird !== '') {
                            elems.push(<p key={i + ',' + ind + ',' + ind2}><span>{nameParse(elemThird[0])}: </span><b>{(typeof valueThird === 'boolean') ? <>{String(valueThird)}</> : <>{valueThird}</>}</b></p>)
                        }
                    })
                }
                
            })
        }
        
    })
    return elems;
}


export const MessagesListing: FC<MessagesListingProps> = ({type, side, msgGet, msgGetClear, msgPostBusiness, msgPostPrivate, msgPostBusinessClear, msgPostPrivateClear, msgViewedPutBusiness, msgViewedPutPrivate, msgViewedPutBusinessClear, msgViewedPutPrivateClear, listing, wasViewedBusiness, wasViewedPrivate, msgElemSentBusiness, msgElemSentPrivate, adminInbox}) => {
    const [comments, setComments] = useState<CommentItem[]>([]);
    const [showEditor, setShowEditor] = useState(false);
    const [userMessages, setUserMessages] = useState<CommentItem[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [expanded, setExpanded] = useState<string[]>([]);
    const [writeTo, setWriteTo] = useState<'BUSINESS' | 'PRIVATE' | ''>(adminInbox ? 'BUSINESS' : '');
    const companies = useSelector((state) => state.admin.backCompany);
    const individuals = useSelector((state) => state.admin.backIndividual);
    const envs = useSelector((state) => state.general.envs);
    const admin = useSelector((state) => state.admin.admin);
    //@ts-ignore
    const msgPosting = useActionStatus(msgPostBusiness);
    //@ts-ignore
    const msgPostingPrivate = useActionStatus(msgPostPrivate);

    const populateMessages = (messagesStateListing: any[] | {companyMessages: any[], individualMessages: any[]}) => {
            let messagesListing:CommentItem[] = [];
            let userMessagesListing:CommentItem[] = [];
            let messagesResponse = [];
            if(adminInbox) {
                //@ts-ignore
                let companyMessages = [...messagesStateListing.companyMessages];
                //@ts-ignore
                let individualMessages = [...messagesStateListing.individualMessages];
                messagesResponse = companyMessages.concat(individualMessages).sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))).reverse();
            } else {
                ///@ts-ignore
                messagesResponse = [...messagesStateListing].sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))).reverse();
            }
            messagesResponse.forEach((message) => {

                let subject = getSubject(message);
                let isExpandedValue = expanded.find((el) => el === message.messageId) ? true : false;
                let isRead = envs.admin ? message.viewedByAdmin ? true : false : message.viewedAt ? true : false;
                let messageAccountType: 'company' | 'individual' | undefined  = adminInbox ? message.companyId ? 'company' : message.individualId ? 'individual' : undefined : undefined;
                let messageAccountId = adminInbox ? message.companyId ? message.companyId : message.individualId ? message.individualId : undefined : undefined;
                //@ts-ignore
                let contentShort = subject?.messageExtract?.length > 40 ? subject.messageExtract.substring(0,40) + '...' : message.message;
                //@ts-ignore
                let subjectShort = subject?.subject?.length > 30 ? subject.subject.substring(0,30) + '...' : message.subject;
                let nameID = message.companyId ? message.companyId : message.individualId ? message.individualId : '';
                let name = '';
                let adminName; 
                if(adminInbox) {
                    if(message.companyId) {
                        //@ts-ignore
                        name = companies?.profiles.find((elem) => message.companyId === elem.companyId)?.name
                    }
                    if(message.individualId) {
                        let prof = individuals?.individuals.find((elem) => message.individualId === elem.individualId)
                        name = prof?.profile?.firstName + ' ' + prof?.profile?.lastName
                    }
                }
                if(admin) {
                    adminName = admin.profiles.find((elem) => elem.adminId === message.createdBy);
                }
                if(message.createdBy) {
                    messagesListing.push({
                        key: message.messageId,
                        id: message.messageId,
                        messageAccountId,
                        subject: subject.subject,
                        subjectShort: subjectShort,
                        //@ts-ignore
                        author: name ? name : nameID,
                        adminAuthor: adminName?.username ? camelCaseToName(adminName.username) : 'Admin',
                        isRead: envs.admin ? true : isRead,
                        isExpanded: isExpandedValue,
                        messageAccountType: messageAccountType,
                        //@ts-ignore
                        contentShort: contentShort,
                        //@ts-ignore
                        content: subject.messageEl,
                        //@ts-ignore
                        datetime: <span>{moment(message.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>,
                    });
                } else {
                    userMessagesListing.push({
                        key: message.messageId,
                        id: message.messageId,
                        messageAccountId,
                        subject: subject.subject,
                        subjectShort: subjectShort,
                        //@ts-ignore
                        author: name ? name : nameID,
                        adminAuthor: adminName?.username ? camelCaseToName(adminName.username) : 'Admin',
                        isRead: !envs.admin ? true : isRead,
                        isUserMessage: true,
                        isExpanded: isExpandedValue,
                        messageAccountType: messageAccountType,
                        contentShort: contentShort,
                        //@ts-ignore
                        content: subject.messageEl,
                        //@ts-ignore
                        datetime: <span>{moment(message.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>,
                    })
                }
            })
            //@ts-ignore
            setComments(messagesListing);
            setUserMessages(userMessagesListing);
            setSubmitting(false);
    }
    const populateNotes = (notesStateListing: any[] | {companyMessages: any[], individualMessages: any[]} ) => {
        let messagesListing:CommentItem[] = [];
        //@ts-ignore
        let messagesResponse = [...notesStateListing].sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))).reverse();
        messagesResponse.forEach((message, i) => {
            let name = admin.profiles.find((elem) => elem.adminId === message.createdBy);
            let isExpandedValue = expanded.find((el) => el === message.id) ? true : false;
            let messageAccountType: 'company' | 'individual' | undefined = adminInbox ? message.companyId ? 'company' : message.individualId ? 'individual' : undefined : undefined;
            let messageAccountId = adminInbox ? message.companyId ? message.companyId : message.individualId ? message.individualId : undefined : undefined;
            //@ts-ignore
            let contentShort = message.note?.length > 40 ? message.note.substring(0,40) + '...' : message.note;
            messagesListing.push({
                key: '' + i,
                id: message.id,
                messageAccountId,
                subject: '',
                subjectShort: '',
                //@ts-ignore
                author: name?.username ? camelCaseToName(name.username) : 'Admin',
                isRead: true,
                isExpanded: isExpandedValue,
                messageAccountType: messageAccountType,
                contentShort: contentShort,
                //@ts-ignore
                content: message.note,
                //@ts-ignore
                datetime: <span>{moment(message.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>,
            })
        })
        //@ts-ignore
        setComments(messagesListing);
        setSubmitting(false);
    }
    const handleRead = (record: CommentItem, expanded: boolean) => {
        const {isRead, id, messageAccountId, messageAccountType} = record;
        if(isRead === false) {
            if(!envs.admin) {
                if(envs.accountType === 'BUSINESS' && msgViewedPutBusiness) {
                    dispatch(msgViewedPutBusiness({params: {companyId: envs.profileId, messageId: id}, data: null}));
                }
                else {
                    if(msgViewedPutPrivate) {
                        dispatch(msgViewedPutPrivate({params: {messageId: id}, data: null}))
                    }
                }
            } else {
                if((messageAccountType === 'company' || envs.accountType === 'BUSINESS') && msgViewedPutBusiness) {
                    dispatch(msgViewedPutBusiness({params: {companyId: adminInbox ? messageAccountId : envs.profileId, messageId: id}, data: null}));
                }
                if((messageAccountType === 'individual' || envs.accountType === 'PRIVATE') && msgViewedPutPrivate) {
                    dispatch(msgViewedPutPrivate({params: {individualId: adminInbox ? messageAccountId : envs.profileId,  messageId: id}, data: null}))
                }
            }
            
        }
       
    }

    useEffect(() => {
        setSubmitting(true);
        if(type === 'note' && listing) {
            populateNotes(listing);
        }
        if(type === 'message' && listing) {
            populateMessages(listing);
        }
    }, [listing]);
    useEffect(() => {
        handleSent(msgPosting, msgElemSentBusiness, msgPostBusinessClear)
       
    }, [msgElemSentBusiness]);
    useEffect(() => {
        handleSent(msgPostingPrivate, msgElemSentPrivate, msgPostPrivateClear)
    }, [msgElemSentPrivate]);


    useEffect(() => {
        if(wasViewedBusiness && (wasViewedBusiness.read === 1)) {
            if(adminInbox) {
                if(msgViewedPutBusinessClear) dispatch(msgViewedPutBusinessClear(null, { payload: null, type: '' }));
                dispatch(msgGet({params: null}));
            } else {
                if(envs.accountType === 'BUSINESS' && msgViewedPutBusinessClear) {
                  dispatch(msgViewedPutBusinessClear(null, { payload: null, type: '' }));
                  dispatch(msgGet({params: {companyId: envs.profileId}}));
                }
                if(envs.admin) {
                    dispatch(adminMessagesGet({params: null}));
                }
            }
          }
    }, [wasViewedBusiness]);
    useEffect(() => {
        if(wasViewedPrivate && (wasViewedPrivate.read === 1)) {
            if(adminInbox) {
                if(msgViewedPutPrivateClear) dispatch(msgViewedPutPrivateClear(null, { payload: null, type: '' }));
                dispatch(msgGet({params: null}));
            } else {
                if(envs.accountType === 'PRIVATE' && msgViewedPutPrivateClear) {
                    dispatch(msgViewedPutPrivateClear(null, { payload: null, type: '' }));
                    if(envs.admin) {
                        dispatch(msgGet({params: {individualId: envs.profileId}}));
                    } else {
                        dispatch(msgGet({params: null}));
                    }
                }
                if(envs.admin) {
                    dispatch(adminMessagesGet({params: null}));
                }
            }
          }
    }, [wasViewedPrivate]);

    const handleSent = (posting: ResponseInfo, elem: string, clearAction?: ClearActionType) => {
        if(posting.isFullfilled || posting.isError) {
            if(type === 'message') {
                if(elem.length) {
                    if(posting.isFullfilled ) {
                        message.success('Message was sucessfully sent');
                        if(posting.isFullfilled && clearAction) {
                            dispatch(clearAction(null, { payload: null, type: '' }));
                        }
                        //@ts-ignore
                        dispatch(msgGet({ params: { [envs.accountType === 'PRIVATE' ? 'individualId' : 'companyId']: envs.profileId } }))
                    }
                } else {
                    if(posting.isError) {
                        message.error('Some error occurred');
                        if(posting.isError && clearAction) {
                            dispatch(clearAction(null, { payload: null, type: '' }));
                        }
                    }
                    
                }
            }
            if(type === 'note') {
                if(elem.length) {
                    if(posting.isFullfilled) {
                        message.success('Note was sucessfully saved');
                        if(posting.isFullfilled && clearAction) {
                            dispatch(clearAction(null, { payload: null, type: '' }));
                        }
                        dispatch(msgGet({ params: { [envs.accountType === 'PRIVATE' ? 'individualId' : 'companyId']: envs.profileId } }))
                    }
                    
                } else {
                    if(posting.isError) {
                        message.error('Some error occurred');
                        if(posting.isError && clearAction) {
                            dispatch(clearAction(null, { payload: null, type: '' }));
                        }
                    }
                    
                }
            }
        }
       
    }
    const handleSubmit = () => {
        setSubmitting(true)
    }
    useEffect(() => {
        if(listing) {
            if(type === 'message') {
                populateMessages(listing);
            }
            if(type === 'note') {
                populateNotes(listing);
            }
        }
        
    }, [expanded]);
    const toggleEditor = () => {
        setShowEditor(!showEditor);
    }
    let unreadLength = 0;
    if(adminInbox) {
        unreadLength = userMessages.filter(el => !el.isRead).length;
    } else {
        unreadLength = comments.filter(el => !el.isRead).length;
    }
    let passProps = {
        handleRead: handleRead,
        type: type,
        admin: envs.admin,
        adminInbox: adminInbox
    }
    return (
        <>
          <CommentCustom
            avatar={false}
            content={
                <>{showEditor || (envs.admin && !adminInbox) ? (
                    <Editor
                        onSubmit={handleSubmit}
                        submitting={submitting}
                        type={type}
                        side={side}
                        writeTo={writeTo}
                        msgPostPrivate={msgPostPrivate}
                        msgPostBusiness={msgPostBusiness}
                        adminInbox={adminInbox}
                    />) : (<></>)}
                </>
            }
          />      
            {type === 'note' ? (
                <>{comments.length > 0 && <CommentList {...passProps} received={false} comments={comments} />}</>
            ) : (
                <TabsCustom defaultActiveKey="1">
                    <TabPane tab="Received messages" key="1">
                        {envs.admin ? (
                            <>{userMessages.length > 0 ? <CommentList {...passProps} received={true} comments={userMessages} userMessages={true} />:<></>}</> 
                        ) : (
                            <>{comments.length > 0 ? <CommentList {...passProps} received={true} comments={comments} />:<></>}</>
                        )}
                    </TabPane>
                    <TabPane tab="Sent messages" key="2">
                        {envs.admin ? (
                            <>{comments.length > 0 ? <CommentList {...passProps} received={false} comments={comments} />:<></>}</>
                        ) : (
                            <>{userMessages.length > 0 ? <CommentList {...passProps} received={false} comments={userMessages} userMessages={true} />:<></>}</>
                        )}
                    </TabPane>
                    {(!envs.admin || adminInbox) &&
                        <TabPane tab={<>
                            <span>{unreadLength} </span><span style={{'marginRight':'10px'}}>{unreadLength > 1 || unreadLength === 0 ? 'unread messages' : 'unread message'}{envs.admin ? '' : ' from Bitclear Admin'}</span>
                            <ButtonUpper className='btn-sm' btnType='primary' onClick={toggleEditor} ghost>{showEditor ? 'Hide new message' : 'New message'}</ButtonUpper>
                            </>} disabled key='3'>
                        </TabPane>
                    }
                </TabsCustom>
            )}
        </>
    );
};
