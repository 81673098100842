import { Table, Space, Typography, Row, Col } from 'antd'
import styled from 'styled-components'
import { useSelector } from 'hooks/useSelector'
import { PageContent } from 'modules/layout/PageContent'
import { dispatch } from 'store/store'
import { FC, useEffect, useState} from 'react'
import { moment, momentNew } from 'modules/kyc-front/ui-elements/FormDate'
import KycLayout, { NavCustom } from 'modules/kyc-front/kyc-layout/KycLayout'
import { ButtonUpper } from 'modules/kyc-front/ui-elements/Buttons'
import TableElement from 'modules/kyc-front/ui-elements/TableElement'
import { setEnvs } from 'store/kyc-front/slices/envsSlice'
import { setNav } from 'store/kyc-front/slices/navSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { themeColor } from 'styles/themeStyles'
import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { clientsGet } from 'store/kyc-backend/asyncThunks/clientsGet'

const { Text } = Typography

const NavCustomStyled = styled(NavCustom)`
    position: absolute;
    top: -40px;
    z-index: 0;
    padding: 0;
    border: 0;
`

const TableCustom = styled(Table)`
    .ant-table-column-sorter-up, .ant-table-column-sorter-down {
        font-size: 15px;
        margin-top: -5px;
    }
`
const ButtonUpperTabs = styled(ButtonUpper)`
	&.btn-sm {
		color: ${themeColor.gray};
        padding: 10px 15px;
        text-transform: none;
        font-size: 14px;    
		&.active {
			color: ${themeColor.primaryColor};
			background-color: ${themeColor.grayWhite};
		}
	}
`

interface CProps {
    listingType: ListingType
}
type ListingType = 'leads' | 'active' | 'closed'


const ClientListing: FC<CProps> = ({listingType}) => {
    const clients = useSelector((state) => state.admin2.clients)
    const envs = useSelector((state) => state.general.envs)
    const {apiURL, admin, adminSuffix, type, env, routeSuffix} = envs
    const sortersList = ['createdAt']
    let navigate = useNavigate()
    let location = useLocation()
    const [activeTab, setActiveTab] = useState<ListingType>(location.pathname === adminSuffix ? 'leads' : location.pathname.includes('active') ? 'active' : location.pathname.includes('closed') ? 'closed' : 'leads')
	useEffect(() => {
        dispatch(clientsGet())
    }, [])
    const confirm = (link: ListingType) => {
        dispatch(setNav({activeListingTab: link}))
        navigate(envs.routeSuffix + '/' + link)
        setActiveTab(link)
    }
	return (
        <KycLayout listingView={true} pageName='Client listing' nav={{hideNav: true, step: -1}}>
            <NavCustomStyled>
                <div className='content'>
                    <Row justify='space-between'>
                        <Col>
                            <ButtonUpperTabs onClick={() => confirm('leads')}  className={activeTab === 'leads' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Leads</ButtonUpperTabs>
                            <ButtonUpperTabs onClick={() => confirm('active')} className={activeTab === 'active' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Active clients</ButtonUpperTabs>
                            <ButtonUpperTabs onClick={() => confirm('closed')} className={activeTab === 'closed' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Closed accounts</ButtonUpperTabs>
                        </Col>
                    </Row>
                </div>
            </NavCustomStyled>
    		<PageContent style={{marginTop: 4, marginBottom: 10}}>
                {clients.clientsList.length ? (
                    <TableElement
                        sortersListProps={sortersList}
                        sortersState={{
                            createdAt: false
                        }}
                        filtersState={{name: false,
                            accountType: '',
                            reviewStatus: '',
                            activeProducts: '',
                            businessRelation: ''
                        }}
                    >
                        {({onTableChange, getColumnFilterProps, defaultCurrent, sorters, filters}) => {
                            const columns = (redirectToUser: any) => {
                                const baseColumns = [
                                  {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (text: string) => <b>{text}</b>,
                                  },
                                  {
                                    title: 'Account type',
                                    dataIndex: 'accountType',
                                    key: 'accountType',
                                    ...getColumnFilterProps('accountType', [
                                      {
                                        text: 'COMPANY',
                                        value: 'COMPANY',
                                      },
                                      {
                                        text: 'INDIVIDUAL',
                                        value: 'INDIVIDUAL',
                                      },
                                    ]),
                                    onFilter: (value: string, record: any) => record.accountType.startsWith(value),
                                    render: (relation: any) => <span>{relation}</span>,
                                  },
                                  {
                                    title: 'Created at',
                                    dataIndex: 'createdAt',
                                    key: 'createdAt',
                                    sortOrder: sorters.createdAt,
                                    //@ts-ignore
                                    sorter: (a: any, b: any) => new Date(a.createdAt) - new Date(b.createdAt),
                                    render: (created: string) => <span>{momentNew({time: created, detailed: true})}</span>,
                                  },
                                  {
                                    title: 'Action',
                                    key: 'action',
                                    render: (el: any) => (
                                      <Space size="middle">
                                        <ButtonUpper className="btn-sm" btnType="primary" onClick={() => redirectToUser(el)} ghost>
                                          Review
                                        </ButtonUpper>
                                      </Space>
                                    ),
                                  },
                                ]
                                if (listingType === 'leads' || listingType === 'closed') {
                                    const kycStatusColumn = {
                                        title: 'KYC status',
                                        dataIndex: 'reviewStatus',
                                        key: 'reviewStatus',
                                        ...getColumnFilterProps('reviewStatus', [
                                            {
                                                text: 'REJECTED',
                                                value: 'REJECTED',
                                            },
                                            {
                                                text: 'ACCEPTED',
                                                value: 'ACCEPTED',
                                            },
                                            {
                                                text: 'CLARIFICATION_NEEDED',
                                                value: 'CLARIFICATION_NEEDED',
                                            },
                                            {
                                                text: 'SUBMITTED',
                                                value: 'SUBMITTED',
                                            },
                                            {
                                                text: 'NEW',
                                                value: 'NEW',
                                            }
                                        ]),
                                        onFilter: (value: string, record: any) =>
                                        record.reviewStatus ? record.reviewStatus.startsWith(value) : value === 'Unknown' ? value === 'Unknown' : false,
                                        render: (status: any) => <span>{status ? status : '-'}</span>,
                                    }
                                    baseColumns.splice(3, 0, kycStatusColumn)
                                }
                                if (listingType === 'active' || listingType === 'closed') {
                                  const productsColumn = {
                                    title: 'Products',
                                    dataIndex: 'activeProducts',
                                    key: 'activeProducts',
                                    ...getColumnFilterProps('activeProducts', [
                                      {
                                        text: 'FX',
                                        value: 'FX',
                                      },
                                      {
                                        text: 'BPG',
                                        value: 'BPG',
                                      },
                                    ]),
                                    onFilter: (value: string, record: any) => record.activeProducts?.includes(value),
                                    render: (types: string[]) => (
                                        <span>
                                        {types.map((type, index) => (
                                            <span key={index}>
                                            {type}
                                            {index < types.length - 1 && <span> | </span>}
                                            </span>
                                        ))}
                                        </span>
                                    ),
                                  }
                                  baseColumns.splice(2, 0, productsColumn)
                                  const businessRelationColumn = {
                                    title: 'Business Relation',
                                    dataIndex: 'businessRelation',
                                    key: 'businessRelation',
                                    ...getColumnFilterProps('businessRelation', [
                                      {
                                        text: 'ACTIVE',
                                        value: 'ACTIVE',
                                      },
                                      {
                                        text: 'INACTIVE',
                                        value: 'INACTIVE',
                                      },
                                    ]),
                                    onFilter: (value: string, record: any) => record.businessRelation === 'ACTIVE' && value === 'ACTIVE' ? true : record.businessRelation === 'INACTIVE' && value === 'INACTIVE' ? true : false,
                                    render: (relation: any) => (
                                        <span>
                                        {relation === 'ACTIVE' ? <><Text type='success'><CheckCircleOutlined style={{marginRight: 8}} /></Text>{relation}</> : <><Text type='warning'><MinusCircleOutlined style={{marginRight: 8}} /></Text>{relation}</> }
                                        </span>
                                    ),
                                  }
                                  baseColumns.splice(4, 0, businessRelationColumn)
                                }
                              
                                return baseColumns
                              }
                            const redirectToUser = (el: any) => {
                                dispatch(setEnvs({apiURL, admin, adminSuffix, type, env, routeSuffix, profileId: el.profileId, accountType: el.accountType === 'COMPANY' ? 'BUSINESS' : 'PRIVATE' }))
                                dispatch(setNav({paginationPage: defaultCurrent, activeListingTab: activeTab, sorters, filters}))
                                navigate(el.routeSuffix + '/' + el.profileId + '/summary')
                            }
                            return (
                                <TableCustom 
                                    pagination={{ showSizeChanger: false, current: defaultCurrent }} 
                                    onChange={onTableChange} 
                                    columns={columns(redirectToUser)}
                                    dataSource={[...clients.clientsList]
                                        .filter(el => 
                                            listingType === 'leads' ? el.status === 'PENDING_KYC' :
                                            listingType === 'active' ? el.status === 'VERIFIED' :
                                            listingType === 'closed' ? el.status === 'ARCHIVED' : false
                                        )
                                        .map((profile, i) => {
                                            const baseProfile = {
                                                name: profile.name,
                                                key: i,
                                                createdAt: profile.createdAt, 
                                                accountType: profile.accountType, 
                                                profileId: profile.profileId,
                                                id: profile.id, 
                                                routeSuffix
                                            }
                                            if (listingType === 'leads') {
                                                return { 
                                                    ...baseProfile, 
                                                    reviewStatus: profile.kycStatus,
                                                };
                                            }
                                            if (listingType === 'active') {
                                                return { 
                                                    ...baseProfile, 
                                                    activeProducts: profile.activeProducts, 
                                                    businessRelation: profile.businessRelation
                                                };
                                            }
                                            if (listingType === 'closed') {
                                                return { 
                                                    ...baseProfile, 
                                                    activeProducts: profile.activeProducts, 
                                                    reviewStatus: profile.kycStatus,
                                                    businessRelation: profile.businessRelation
                                                };
                                            }
                                
                                            return baseProfile
                                        })
                                    }
                                />
                            )
                        }}
                    </TableElement>
                ) : (<div></div>)}
                <br />
    		</PageContent>
        </KycLayout>
	)
}

export default ClientListing
