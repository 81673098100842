import * as Yup from 'yup'
import Title from 'antd/es/typography/Title'
import { Space, Row, Col, Card, message, Popover } from 'antd'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import LoginBG from '../../assets/login-bg.png'
import FormInput from './ui-elements/FormInput'
import { dispatch } from '../../store/store'
import Logo from '../../assets/logo-kyc.png'
import IndividualIcon from '../../assets/individual.svg'
import CompanyIcon from '../../assets/corpo.svg'
import { useActionStatus } from '../../hooks/useActionStatus'
import { Link } from 'react-router-dom'
import { userCreate } from '../../store/kyc-front/asyncThunks/userCreate'
import { userCreateClear } from '../../store/kyc-front/slices/userSlice'
import BasicFormWrapper from './ui-elements/BasicForm'
import { themeColor } from '../../styles/themeStyles'
import { useSelector } from '../../hooks/useSelector'
import { SuccessIcon } from './ui-elements/SuccessInfo'

const CenteredCol = styled(Col)`
	text-align: center;
	margin-bottom: 30px;
`

const CardCustom = styled(Card)`
	text-align: center;
	padding: 18px;
	max-width: 280px;
	.ant-card-body {
		padding: 0;
	}
	img {
		width: 70px;
		margin-bottom: 15px;
		filter: grayscale(1);
	}
	p {
		margin-bottom: 0;
		font-weight: 500;
		color: #b1b1b1;
	}
	&:hover, &:active, &.selected {
		border-color: ${themeColor.primaryColor};
		cursor: pointer;
		img {
			filter: none;
		}
		p {
			color: #000;
		}
	}
	&.smaller {
		padding-top: 15px;
		padding-bottom: 15px;
		img {
			width: 50px;
		}
	}
	&.disabled {
		background-color: ${themeColor.grayWhite};
		cursor: not-allowed;
		&:hover, &:active, &.selected {
			border-color: ${themeColor.grayWhite};
			img {
				filter: grayscale(1); 
			}
			p {
				color: #b1b1b1;
			}
		}
	}
`
export const PageContentCustom = styled.div`
	z-index: 1;
	width: 100%;
	height: 100%;
	position: relative;
	margin: 0 auto;
	max-width: 400px;
	padding: 0;
	overflow: hidden;
	&.register-page {
		max-width: 650px;
	}
`

export const Login = styled(Card)`
	background-color: #fff;
	margin-top: 40px;
	margin-top: calc(50vh - 180px);
	margin-bottom: 15px;
	.ant-card-body {
		padding-top: 20px;
	}
	.ant-btn-primary {
		width: 100%;
	}
	&.register-login {
		margin-top: 20px;
		margin-top: calc(50vh - 400px)
	}
`
export const ImgLogo = styled.img`
	max-width: 280px;
	margin-bottom: 15px;
`
export const LoginRegisterBg = styled.div`
	background-color: #6699ff;
	background: url(${LoginBG});
	background-size: cover;
	position: fixed;
	top: 0;
	height: 100%;
	height: 100vh;
    width: 100%;
    width: 100vw;
    overflow: hidden;
`

const KycRegister: FC = () => {
	const envs = useSelector((state) => state.general.envs);
	const user = useSelector((state) => state.general.user)
	const [clientType, setClientType] = useState('INDIVIDUAL');
	const [success, setSuccess] = useState(false);
	const userCreateStatus = useActionStatus(userCreate);
    useEffect(() => {
    	if(userCreateStatus.isFullfilled) {
    		setSuccess(true);
    		dispatch(userCreateClear({}));
    	}
    	if(userCreateStatus.isError) {
			//@ts-ignore
			if(user.new.status === 409) {
    			message.error('This e-mail address can not be registered');
			} else {
				message.error('Some error occurred, please try again');
			}
    		dispatch(userCreateClear({}))
    	}
    	
    }, [userCreateStatus])
    const initialValues = { 
		clientType: clientType,
		email: '',
		password: '',
		repeatPassword: ''
    }
    const validation = Yup.object({
		email: Yup.string().email('Should be valid email address').required(),
		password: Yup.string().min(8, 'Should be at least 8 characters').required(),
		repeatPassword: Yup.string()
		.test('passwords-match', 'Passwords must match', function(value){
			return this.parent.password === value
		})
    });
  	const onSubmitted = () => {
    	dispatch(userCreateClear({}))
  	};
  	const onCleared = () => {
  		dispatch(userCreateClear({}))
  	}
	const handleClientChange = (client: string) => {
		setClientType(client);
	};
  	return (
  		<>
	  		<PageContentCustom className='register-page'>	
	  			<Login bordered={false} className='register-login'>
	  				{success ? (
	  					<div style={{marginTop: 20}}>
							<Row justify='center'>
								<CenteredCol style={{marginBottom: 10}}>
									<SuccessIcon />
									<Title level={4} style={{marginBottom: 30}}>Congratulations!</Title>
									<p>Registration form submitted successfully</p>
								</CenteredCol>
							</Row>
							<Row justify='center'>
								<CenteredCol>
									<p>We've sent an email with a registration link to your email address.</p> 
									<p style={{marginTop: 25}}>Click on the link included in the email to complete the proccess. </p>
								</CenteredCol>
							</Row>
						</div>

	  				) : (
			  			<BasicFormWrapper id='registerForm' initial={initialValues} validation={validation} successCondition={'success'} registerSubmit={true} validateOnBlur={true} >
			            {({handleInputChange, values, disabled, errors, touched}) => {
			                return (
			                    <>
			                    	<div style={{textAlign: 'center', borderBottom: `2px solid ${themeColor.grayWhite}`}}>
					  					<ImgLogo src={Logo} alt='Bitclear KYC'/>
					  				</div>
			                        <Space size={15} direction='vertical'>
			                        	<Row>
			                        		<Col span={24}>
				                        		<Title level={3}>Register to Bitclear</Title>
				                        	</Col>
			                        	</Row>
										<Row gutter={30}>
					                    	<Col span={24}>
					                    		<span>Choose account type:</span>
					                    	</Col>
					                    </Row>
					                    <Row gutter={30}>
			                        		<Col span={12}>
			                        			<Popover content={'You want to create account just for Yourself'}>
				                        			<CardCustom className={clientType === 'INDIVIDUAL' ? 'selected smaller' : ' smaller'} onClick={() => handleClientChange('INDIVIDUAL')}>
													    <img src={IndividualIcon} alt='Individual Client Icon' />
													    <p>Individual</p>
													</CardCustom>
												</Popover>
											</Col>
											<Col span={12}>
												<Popover content={<span>You want to create account for Your business</span>}>
													<CardCustom className={clientType === 'COMPANY' ? 'selected smaller' : 'smaller'} onClick={() => handleClientChange('COMPANY')}>
												    	<img src={CompanyIcon} alt='Company Icon' />
												    	<p>Company</p>
													</CardCustom>
												</Popover>
			                        		</Col>
			                        	</Row>
						                <Row>
						                   <Col span={24}>
						                       <FormInput label='Email address *' name='email' error={touched.email ? errors.email : undefined} onChange={handleInputChange} disabled={disabled} value={values.email} type='individual' />
						                   </Col>
										</Row>
										<Row>
						                   <Col span={24}>
						                       <FormInput label='Password * (min 8 characters)' inputType='password' name='password' error={touched.password ? errors.password : undefined} onChange={handleInputChange} disabled={disabled} value={values.password} type='individual'  />
						                   </Col>
						                </Row>
										<Row style={{marginBottom: 20}}>
						                   <Col span={24}>
						                       <FormInput label='Repeat password *' inputType='password' name='repeatPassword' error={touched.repeatPassword ? errors.repeatPassword : undefined} onChange={handleInputChange} disabled={disabled} value={values.repeatPassword} type='individual'  />
						                   </Col>
						                </Row>
			                        </Space>
			                    </>
			                )
			            }}
			        	</BasicFormWrapper>
			        )}
		        </Login>
		        <p style={{color: '#fff', textAlign: 'center'}}>Already have an account? <Link to={envs.routeSuffix + '/login'}><b>Login</b></Link></p>
	        </PageContentCustom>
        <LoginRegisterBg />
        </>
  	);
}


export default KycRegister

