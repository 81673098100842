import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { authorizedPersonBackUpdate } from "../asyncThunks/authorizedPersonBackUpdate";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";

import { reduce } from "lodash";

export type APResponse = Components.Schemas.AuthorizedPersonProfile;
export type ArchiveReponse = Paths.ArchiveAuthorizedPerson.Responses.$200;

type State = {
  updatedAuthorizedPerson: APResponse;
};

const initialState: State = {
  updatedAuthorizedPerson: {
    authorizedPersonId: '',
    companyId: '',
    firstName: '',
    lastName: '',
    createdAt: ''
  }
};

type CaseReducers<State> = {
  authorizedPersonBackUpdateClear: CaseReducerType<State, object>;
};

export const authorizedPersonsSlice = createSlice<State, CaseReducers<State>>({
  name: "authorizedPersonsBack",
  initialState,
  reducers: {
    authorizedPersonBackUpdateClear: (state, { payload }) => {
      state.updatedAuthorizedPerson = initialState.updatedAuthorizedPerson
    }
  },
  extraReducers: (builder) => {
    builder.addCase(authorizedPersonBackUpdate.fulfilled, (state, action) => {
      state.updatedAuthorizedPerson = action.payload;
    });
  }
});

export const { authorizedPersonBackUpdateClear } = authorizedPersonsSlice.actions;
export default authorizedPersonsSlice.reducer;
