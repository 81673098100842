import { FC, ReactNode, useEffect } from 'react'
import { readyForReviewBackValidateClear, individualBackReadyForReviewValidateClear } from 'store/kyc-backend/slices/statusSlice'
import { companyDocsGetClear } from 'store/kyc-backend/slices/companyDocsSlice'
import { uboBackDocsGetClear } from 'store/kyc-backend/slices/uboDocsSlice'
import { authorizedPersonDocsBackGetClear } from 'store/kyc-backend/slices/authorizedPersonDocsSlice'
import { companyProfileBackGetClear, archivedCompanyProfilesBackGetClear } from 'store/kyc-backend/slices/companySlice'
import { individualProfileBackGetClear } from 'store/kyc-backend/slices/individualSlice'
import { setFormUpdated } from 'store/kyc-front/slices/navSlice'
import { identityGetClear } from 'store/kyc-backend/slices/identitySlice'
import { dispatch } from 'store/store'
import { kycApplicationApprovalsGetClear } from 'store/kyc-backend/slices/backKycApplication'
import { kycApplicationGetClear, kycApplicationValidateClear } from 'store/kyc-front/slices/kycApplicationSlice'

const ClearUserState: FC<{ children?: ReactNode }> = ({ children }) => {
    useEffect(() => {
        dispatch(readyForReviewBackValidateClear({}))
        dispatch(individualBackReadyForReviewValidateClear({}))
        dispatch(authorizedPersonDocsBackGetClear({}))
        dispatch(uboBackDocsGetClear({}))
        dispatch(companyDocsGetClear({}))
        dispatch(companyProfileBackGetClear({}))
        dispatch(archivedCompanyProfilesBackGetClear({}))
        dispatch(individualProfileBackGetClear({}))
        dispatch(identityGetClear({}))
        dispatch(kycApplicationGetClear({}))
        dispatch(kycApplicationValidateClear({}))
        dispatch(kycApplicationApprovalsGetClear({}))
        dispatch(setFormUpdated({updated: false, form: []}))
	}, []);
    return <>{children}</>
	
}
export const AdminRoute: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<ClearUserState>
			{children}
		</ClearUserState>
	)
}
