import { Space, Row, Col, Progress, Layout, Badge, Menu, Dropdown, Modal, message } from 'antd'
import type { MenuProps } from 'antd'
import _ from 'lodash'
import Title from 'antd/es/typography/Title'
import { CaretLeftOutlined, EllipsisOutlined } from '@ant-design/icons'
import Text from 'antd/es/typography/Text'
import { FC, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { logout, logoutUser } from 'modules/keycloak'
import { setEditedFormConfirmed, setNav } from 'store/kyc-front/slices/navSlice'
import { setEnvs } from 'store/kyc-front/slices/envsSlice'
import { kycApplicationValidateClear } from 'store/kyc-front/slices/kycApplicationSlice'
import { companyDocsGetClear as companyDocsGetBackClear} from 'store/kyc-backend/slices/companyDocsSlice'
import { adminMessagesGet } from 'store/kyc-backend/asyncThunks/adminMessagesGet'
import { companyDocsGetClear } from 'store/kyc-front/slices/companyDocsSlice'
import { useSelector } from 'hooks/useSelector'
import { dispatch } from 'store/store'
import { Components } from 'api/kyc/generated/client'
import { PageContent } from 'modules/layout/PageContent'
import { ButtonUpper } from 'modules/kyc-front/ui-elements/Buttons'
import { adminProfileGet } from 'store/kyc-backend/asyncThunks/adminProfileGet'
import { adminProfilesGet } from 'store/kyc-backend/asyncThunks/adminProfilesGet'
import { adminProfileGetClear } from 'store/kyc-backend/slices/adminSlice'
import { profileGetClear } from 'store/kyc-front/slices/profileSlice'
import { individualGetClear } from 'store/kyc-front/slices/individualSlice'
import { identityGetClear } from 'store/kyc-front/slices/identitySlice'
import { useActionStatus } from 'hooks/useActionStatus'
import { camelCaseToName } from 'utilities/nameParsers'
import Error403 from 'modules/kyc-front/Error403'
import FooterDrawer from 'modules/kyc-front/kyc-layout/KycFooterDrawer'
import { KycSteps } from 'modules/kyc-front/kyc-layout/KycSteps'
import KycMessages from 'modules/kyc-messages/KycMessages'
import Logo from 'assets/logo-kyc.png'

import { FormSaver } from 'utilities/FormSaver'

import { KycNav } from 'modules/kyc-front/kyc-layout/KycNav'
import { themeColor, themeSize, themeScreen } from 'styles/themeStyles'
import { kycApplicationSendBack } from 'store/kyc-backend/asyncThunks/kycApplicationSendBack'
import TextArea from 'antd/es/input/TextArea'
import { kycApplicationGet } from 'store/kyc-front/asyncThunks/kycApplicationGet'
import { kycApplicationSendBackClear } from 'store/kyc-backend/slices/backKycApplication'
import { ENV } from "index"
const { Footer, Header } = Layout



const PageName = styled(Text)`
	color: ${themeColor.primaryColor};
	font-size: ${themeSize.heading3};
	font-weight: 500;
`
const ButtonGroupItem = styled(ButtonUpper)`
	width: 100%;
`
const Sticky = styled.div`
	position: -webkit-sticky;
  	position: sticky;
  	z-index: 9;
  	&.no-stick {
  		position: relative;
  	}
`
const Fixed = styled.div`
  	position: fixed;
  	z-index: 10;
	width: 100%;
	.content {
		max-width: ${themeScreen.maxContent};
	}
  	&.no-stick {
  		position: relative;
  	}
`
const CustomBadge = styled(Badge)`
   z-index: 9;
   display: block;
	width: 100%;
	.ant-badge-count {
		padding: 1px 6px;
		
	}
	.ant-btn-dashed {
		padding: 0;
    	height: 33px;
    	font-size: 14px;
	}
`

const CustomProgress = styled(Progress)`
	&.ant-progress-circle.ant-progress-status-success .ant-progress-text {
		color: ${themeColor.green}!important;
	}
`

export const ImgLogo = styled.img`
	max-width: 265px;
	margin-left: -45px;
`

export const HeaderCustom = styled(Header)`
	position: fixed;
	position: sticky;
	z-index: 99;
	width: 100%;
	top: 0;
	border-bottom: 1px solid ${themeColor.grayWhite};
	.content {
		margin: auto;
		max-width: ${themeScreen.maxContent};
	}
`
export const FooterCustom = styled(Footer)`
	width: 100%;
	border-top: 1px solid ${themeColor.grayWhite};
	.content {
		margin: auto;
		max-width: ${themeScreen.maxContent};
	}
`

const FooterCustomModule = styled(Footer)`
	padding: 0;
	max-width: ${themeScreen.maxContent};
`

export const NavCustom = styled.div`
	border-bottom: 1px solid ${themeColor.grayWhite};
	position: fixed;
	position: sticky;
	z-index: 99;
	width: 100%;
	top: 65px;
	background-color: #fff;
	padding-top: 8px;
	padding-bottom: 8px;
	.content {
		margin: auto;
		max-width: ${themeScreen.maxContent};
	}
	p {
		margin-bottom: 0;
		padding-top: 3px;
		color: ${themeColor.gray};
		.client {
			color: #000;
		}
	}
`

export const NavCustomTabs = styled(NavCustom)`
	top: 108px;
	padding-top: 0;
	padding-bottom: 0;
`

export const ButtonUpperCustom = styled.span`
	.btn-sm {
		font-size: 14px;
		font-weight: 500;
		padding-left: 0;
		color: ${themeColor.gray};
	}
`

const ButtonUpperTabs = styled(ButtonUpper)`
	&.btn-sm {
		color: ${themeColor.gray};
		padding-top: 8px;
		padding-bottom: 8px;
		&.active {
			color: ${themeColor.primaryColor};
			background-color: ${themeColor.grayWhite};
		}
	}
`

type ReviewStatusType = Components.Schemas.ReviewStatusType

interface IProps {
	approve?: boolean,
	archive?: boolean,
	restore?: boolean,
	listingView?: boolean,
	pageName?: string,
	heading?: string,
	adminView?: boolean,
	children: ReactNode,
	validationAction?: (value: any) => void,
	validationActionParams?: object,
	hideValidate?: boolean,
	hideSaveButton?: boolean,
	hideApproveStepButton?: boolean,
	setErrorsListing?: (value: any) => any,
	nav: {
		next?: string,
		step?: number,
		substep?: number,
		refId?: string,
		nextName?: string,
		nextButton?: string,
		prev?: string,
		prevName?: string,
		status?: ReviewStatusType | undefined,
		hideNav?: boolean,
		hideSteps?: boolean,
		navPage?: string,
		navSubpage?: string,
		link?: string
	}
}

type AProps = {
	handleDropMenuClick: (value: any) => void
}

type SProps = {
	open: boolean,
	handleOk: (value: any) => void,
	handleCancel: (value: any) => void
}

const MenuCustom = styled(Menu)`
	position: relative;
	top: -1px;
	&.ant-menu-dark {
		background: #fff;
		.ant-menu-item {
			color: #000;
			font-weight: bold;
			&.ant-menu-item-selected {
				background-color: #f6f6f6;
				color: ${themeColor.primaryColor};
			}
			&:hover {
				background-color: #f6f6f6;
			}
		}
	}
	
	
`

const items = (isAdmin: boolean, count: number) => {
	if(isAdmin) {
		return ([
			{
				label: 'Client profiles',
				key: '',
			},
			/*{
				label: 'Archived clients',
				key: 'archived'
			},*/
			{
				label: <>Messages <Badge count={count} /></>,
				key: 'messages'
			}
		])
	} else {
		return ([
			{
				label: 'KYC Profile',
				key: 'profile',
			},
			{
				label: <>Messages <Badge count={count} /></>,
				key: 'inbox'
			}
		])
	}
}

const actionItems: MenuProps['items'] = [
	{
	  label: 'Send back to Client',
	  key: 'back',
	},
]
const ActionMenuDropdown: FC<AProps> = ({handleDropMenuClick}) => {
	const onClick: MenuProps['onClick'] = (e) => {
		handleDropMenuClick(e)
	}
	return (
		<span style={{marginLeft: 5}}>
			<Dropdown menu={{items: actionItems, onClick: onClick}} trigger={['click']} >
				<ButtonUpper btnType="default" className='btn-sm'>
					<EllipsisOutlined />
				</ButtonUpper>
			</Dropdown>
		</span>
	)
}

const SendBackModal: FC<SProps> = ({open, handleOk, handleCancel}) => {
	const [description, setDescription] = useState('')
	const handleDescriptionChange = (el: any) => {
		setDescription(el.target.value)
	}
	return (
		<Modal
			title="Send back to Client"
			open={open}
			onOk={() => handleOk(description)}
			okText='Send'
			onCancel={handleCancel}
		>
			<br />
			<label>Provide clarification which will be sent to the client (optional)</label>
			<TextArea style={{resize: "none", marginTop: 5, marginBottom: 15}} onChange={handleDescriptionChange} rows={5} value={description}></TextArea>
			<br />
      </Modal>
	)
}

export const KycLayout: FC<IProps> = ({
	pageName, heading, adminView, children, nav, validationAction, validationActionParams, approve, archive, restore, listingView, hideValidate, hideSaveButton, hideApproveStepButton, setErrorsListing
}) => {
	let {next, step, substep, refId, nextName, nextButton, prev, prevName, status, hideNav, hideSteps, navPage, navSubpage, link} = nav
	const envs = useSelector((state) => state.general.envs)
	const {kycApplication} = useSelector((state) => state.user2.kycApplication)
	const profile = useSelector((state) => envs.admin ? state.admin.backCompany.profile : state.user.profile.userProfile)
	const individualProfile = useSelector((state) => envs.admin ? state.admin.backIndividual.individual : state.user.individual.individual)
	const {apiURL, admin, adminSuffix, type, env, routeSuffix} = envs
	const adminProfile = useSelector((state) => state.admin.admin)
	const adminInbox = useSelector((state) => state.admin.adminInbox)
	const navEl = useSelector((state) => state.general.nav)
	const { session } = useSelector((state) => state.general.auth)
	const adminProfileLoaded = useActionStatus(adminProfileGet)
   	const [navSearch, setNavSearch] = useState('')
   	const statusInfo = kycApplication.status
   	const relationStatus = envs.accountType === 'BUSINESS' ? profile?.profile?.businessRelation?.isActive : individualProfile?.businessRelation?.isActive
   	const [current, setCurrent] = useState(navEl.nav.navPage ? navEl.nav.navPage : '')
	const [showSendBackModal, setShowSendBackModal] = useState(false)
   	const profileArchived = profile?.profile?.archivedAt || individualProfile?.archivedAt ? true : false
   	const adminMessagesGetStatus = useActionStatus(adminMessagesGet)
	const kycApplicationSendBackStatus = useActionStatus(kycApplicationSendBack)
   	let messagesCount = 0
   	//@ts-ignore
   	if(adminInbox?.messages.companyMessages.length || adminInbox?.messages.individualMessages.length) {
   		//@ts-ignore
   		messagesCount = adminInbox.messages.companyMessages.filter((messageEl) => !messageEl.viewedByAdmin && !messageEl.createdBy).length + adminInbox.messages.individualMessages.filter((messageEl) => !messageEl.viewedByAdmin && !messageEl.createdBy).length
   	}
   	const onClick: MenuProps['onClick'] = e => {
	    setCurrent(e.key)
	    dispatch(setNav({next, step, substep, refId, nextName, nextButton, prev, prevName, status, hideNav, navPage: e.key, paginationPage: navEl.nav.paginationPage, sorters: navEl.nav.sorters, filters: navEl.nav.filters, navSubpage, link: link, activeListingTab: navEl.nav.activeListingTab}))
		dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: '/' + e.key, general: envs.admin, showErrors: false}))
	}
	useEffect(() => {
		if(nav.navPage) {
			setCurrent(nav.navPage)
		}
		dispatch(setNav({next, step, substep, refId, nextName, nextButton, prev, prevName, status, hideNav, navPage: nav.navPage ? nav.navPage : navEl.nav.navPage, paginationPage: navEl.nav.paginationPage, sorters: navEl.nav.sorters, filters: navEl.nav.filters, navSubpage, link: link, activeListingTab: navEl.nav.activeListingTab}))
		if(envs.admin && type === 'standalone') {
			if(!adminMessagesGetStatus.isFullfilled) {
				dispatch(adminMessagesGet({params: null}))
			}
			const interval = setInterval(() => {
				dispatch(adminMessagesGet({params: null}))
			}, 60000)
			return () => clearInterval(interval)
		}
	}, [])
	useEffect(() => { 
		if(profileArchived && current !== 'archived') {
			setCurrent('archived')
			let currNav = navEl.nav
			dispatch(setNav({next: currNav.next, step: currNav.step, substep: currNav.substep, refId: currNav.refId, nextName: currNav.nextName, nextButton: currNav.nextButton, prev: currNav.prev, prevName: currNav.prevName, status: currNav.status, hideNav: currNav.hideNav, navPage: 'archived', paginationPage: currNav.paginationPage, sorters: currNav.sorters, filters: currNav.filters, navSubpage, link: link, activeListingTab: navEl.nav.activeListingTab}))
		}
	}, [profileArchived])
	useEffect(() => {
		if(nav.navPage) {
			setCurrent(nav.navPage)
		}
		dispatch(setNav({next, step, substep, refId, nextName, nextButton, prev, prevName, status, hideNav, navPage: nav.navPage ? nav.navPage : navEl.nav.navPage, paginationPage: navEl.nav.paginationPage, sorters: navEl.nav.sorters, filters: navEl.nav.filters, navSubpage, link: link, activeListingTab: navEl.nav.activeListingTab}))
	}, [status, prev, next, step, navPage, status])
	useEffect(() => {
		if(envs.admin) {
			 if(adminProfile.profile.adminId === '') {
            dispatch(adminProfileGet({params: null}))
         }
         if(adminProfile.profiles.length === 0) {
            dispatch(adminProfilesGet({params: null}))
         }
		}
    }, [adminProfile])
    useEffect(() => {
    	let navSearchNew = navEl.nav.paginationPage ? '?page=' + navEl.nav.paginationPage : ''
	    if(navEl.nav.sorters) {
	    	_.forEach(navEl.nav.sorters, (val, name) => {
	    		if(val) {
	    			let prefix = navSearchNew !== '' ? '&' : '?'
	    			navSearchNew += prefix + name + '=' + val
	    		}
	    	})
	    }
	    if(navEl.nav.filters) {
	    	_.forEach(navEl.nav.filters, (val, name) => {
	    		if(val) {
	    			let prefix = navSearchNew !== '' ? '&' : '?'
	    			navSearchNew += prefix + name + '=' + val
	    		}
	    	})
	    }
	    setNavSearch(navSearchNew)
    }, [navEl.nav])
	useEffect(() => {
		if(kycApplicationSendBackStatus.isFullfilled) {
			message.success('Profile was sent back to Client')
			dispatch(kycApplicationGet({params: {id: envs.profileId}}))
			dispatch(kycApplicationSendBackClear({}))
		}
		if(kycApplicationSendBackStatus.isError) {
			message.error('Some error occurred while sending back to Client')
			dispatch(kycApplicationSendBackClear({}))

		}
	}, [kycApplicationSendBackStatus])
	const handleLogoutClick = () => {
		if(envs.isBusiness) {
			if(envs.admin) {
				dispatch(adminProfileGetClear({}))
				dispatch(companyDocsGetBackClear({}))
			} else {
				dispatch(companyDocsGetClear({}))
				dispatch(identityGetClear({}))
				dispatch(profileGetClear({}))
			}
			
		} else {
			if(envs.admin) {
				dispatch(adminProfileGetClear({}))
			} else {
				dispatch(identityGetClear({}))
				dispatch(individualGetClear({}))
			}
		}
		dispatch(kycApplicationValidateClear({}))
		dispatch(setEnvs({apiURL, admin, adminSuffix, type, env, routeSuffix, profileId: '', accountType: ''}))
		onLogout()
	}
	const onLogout = async () => {
		await logoutUser({
			refreshToken: session?.refresh_token,
			keycloakDomain: ENV.KEYCLOAK_DOMAIN,
			client_id: envs.admin ? ENV.KEYCLOAK_CLIENT_BACK : ENV.KEYCLOAK_CLIENT_FRONT,
			//@ts-ignore
			is_backend: envs.admin,
			callback: () => dispatch(logout(true))
		})
	}
	const handleDropMenuClick = (e: any) => {
		if(e.key === 'back') {
			setShowSendBackModal(true)
		}
	}
	const handleSendBack = (descr: string) => {
		dispatch(kycApplicationSendBack({params: {id: envs.profileId}, data: descr}))
		setShowSendBackModal(false)
	}
	const confirm = (link: string, general?: boolean) => {
        dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: link === '' ? '/' : link, general, showErrors: false}))
    }
	return (
		<>
			{envs.type === 'standalone' &&
				<HeaderCustom>
						<div className='content'>
							<Row>
								<Col sm={8} md={7} lg={6} xl={5}>
						 			<ImgLogo src={Logo} alt='Bitclear KYC' />
						 		</Col>
						 		<Col sm={8} md={7} lg={10} xl={11}>
							 		{envs.type === 'standalone' &&
							 			/*@ts-ignore*/
							 			<MenuCustom theme='dark' style={{ width: 510 }} onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items(envs.admin, messagesCount)} />
							 		}
						 		</Col>
						 		<Col sm={8} md={10} lg={8} xl={8}>
							 		<div style={{color: themeColor.gray, textAlign: 'right'}}>
							 			<span style={{color: '#000'}}>Hello, {envs.admin ? camelCaseToName(adminProfile.profile.username) : envs.accountType === 'BUSINESS' ? profile.profile.name : individualProfile.profile.firstName ? individualProfile.profile.firstName : individualProfile.profile.lastName ? individualProfile.profile.lastName : 'there'}</span>
							 			<span> | </span>
							 			<a onClick={handleLogoutClick}><b>Logout</b></a>
							 		</div>
						 		</Col>
						 		
						 	</Row>
					    </div>
				</HeaderCustom>
			}
			{envs.admin && envs.type === 'standalone' && 
				<KycMessages />
			}
			{envs.type === 'standalone' && envs.admin && nav.step !== -1 &&
				<>
					<NavCustom style={{top: messagesCount ? 122 : 65}}>
						<div className='content'>
							<Row justify='space-between'>
								<Col>
									<ButtonUpperCustom onClick={() => confirm(navEl.nav.navPage ?  navEl.nav.navPage + navSearch : navEl.nav.activeListingTab ? navEl.nav.activeListingTab === 'leads' ? navSearch : '/' + navEl.nav.activeListingTab + navSearch : navSearch, true)}><ButtonUpper className='btn-sm' btnType='text'><CaretLeftOutlined />Back to listing</ButtonUpper></ButtonUpperCustom>
								</Col>
								<Col>
									<p>
										<b className='client'>{envs.isBusiness ? profile.profile.name : <>{kycApplication.values?.firstName ? kycApplication.values?.firstName : 'New'} {kycApplication.values?.lastName ? kycApplication.values?.lastName : 'Person'}</>}</b> | 
										<Text type={relationStatus ? "success" : "secondary"}><b> {relationStatus ? 'Active' : 'Not active'}</b></Text>
										{!relationStatus && <> | <Text type={statusInfo === 'SUBMITTED' || statusInfo === 'ACCEPTED' ? "success" : statusInfo === 'NEW' ? "secondary" : "secondary"}><b> KYC {profileArchived ? 'Archived' : statusInfo === 'CLARIFICATION_NEEDED' ? 'Clarification needed' : statusInfo} </b></Text></>}
										{statusInfo === 'SUBMITTED' && <ActionMenuDropdown handleDropMenuClick={handleDropMenuClick} />}
									</p>
								</Col>
							</Row>
						</div>
					</NavCustom>
					<NavCustomTabs style={{top: messagesCount ? 165 : 108}}>
						<div className='content'>
							<Row justify='space-between'>
								<Col>
									<ButtonUpperTabs onClick={() => confirm('/summary')}  className={navEl.nav.navSubpage === 'kyc' ? 'btn-sm active' : 'btn-sm'} btnType='text'>KYC</ButtonUpperTabs>
								
									<ButtonUpperTabs onClick={() => confirm('/business-profile')} className={navEl.nav.navSubpage === 'business-profile' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Business Profile</ButtonUpperTabs>
								
									<ButtonUpperTabs onClick={() => confirm('/risk-profile')} className={navEl.nav.navSubpage === 'risk-profile' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Risk profile</ButtonUpperTabs>
								
								{/*
									<Col>
										<Link to={routeSuffix + '/' + profileId + '/world-check'}><ButtonUpperTabs className={navEl.nav.navSubpage === 'world-check' ? 'btn-sm active' : 'btn-sm'} btnType='text'>World Check</ButtonUpperTabs></Link>
									</Col>
									<Col>
										<Link to={routeSuffix + '/' + profileId + '/transactions'}><ButtonUpperTabs className={navEl.nav.navSubpage === 'transactions' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Transactions</ButtonUpperTabs></Link>
									</Col>
								*/}
									<ButtonUpperTabs onClick={() => confirm('/relation')}  className={navEl.nav.navSubpage === 'relation' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Business Relation</ButtonUpperTabs>

								</Col>
							</Row>
						</div>
					</NavCustomTabs>
				</>
			}
			{!envs.admin && envs.type === 'standalone' && nav.step !== -1 &&
				<KycMessages />
			}
			<FormSaver />
			<div style={{'height': '100%'}}>
				{envs.admin && adminProfile.profile.adminId === '' && adminProfileLoaded.isFullfilled || adminProfileLoaded.isError ? (
					<Error403 />
				) : (
					<PageContent>		
						{listingView === true ? (
							<Row gutter={30}>
								{children}
							</Row>) : ( 	
							<>	    
							<Row gutter={30}>
							    <Col sm={24} md={hideSteps ? 24 : 12} lg={hideSteps ? 24 : 18} xl={hideSteps ? 24 : 18} id='pageWrapper' style={{marginBottom: envs.admin ? '150px' : '70px'}}>
							    	<Space direction='vertical' size={0} style={{'marginBottom': '15px'}}>
							    		<Space direction='vertical' size={'middle'}>
								    		{pageName && <PageName>{pageName}</PageName>}
								      	<Title level={1}>{heading}</Title>
								      </Space>
								    	{children}
									</Space>
									{!hideNav &&
										<Fixed className={((status === 'ACCEPTED' || status === 'SUBMITTED') && step === 0) ? 'no-stick' : ''} style={{'bottom': `${envs.admin ? '57px' : '0'}`}}>
											<div className='content' style={{'borderTop': `2px solid ${themeColor.grayWhite}`, background: '#fff'}}>
												<KycNav approve={approve} archive={archive} restore={restore} validationAction={validationAction} validationActionParams={validationActionParams} hideValidate={hideValidate} hideSaveButton={hideSaveButton} hideApproveStepButton={hideApproveStepButton} setErrorsListing={setErrorsListing} />
											</div>
										</Fixed>
									}
									
							     </Col>
							     {!hideSteps &&
									<Col sm={24} md={12} lg={6} xl={6} style={{marginBottom: envs.admin ? '150px' : '70px'}}>
										<Sticky style={{'top': envs.admin && envs.type === 'standalone' ? '170px' : '80px'}}>
											<div>
												<Space direction='vertical' size={30}>
													<Space direction='vertical' size={'middle'}>
														<Title level={5}>KYC steps</Title>
													<KycSteps adminView={adminView} />
													</Space>
												
											</Space>
										</div>
										</Sticky>
									</Col>
								 }
								</Row>
								{envs.admin &&
									<Fixed style={{bottom: 0}}>
										<FooterCustomModule style={{'bottom':'0', 'borderTop': `2px solid ${themeColor.grayWhite}`}}>
											<Row style={{zIndex: 1, background: '#fff', padding: '10px 30px', marginLeft: '-30px', marginRight: '-30px', justifyContent: 'space-between'}}>
								            	{/*<Col span={6}>
										            <ButtonGroupItem btnType="dashed" block size='small' danger>Delete Profile</ButtonGroupItem>
								            	</Col>*/}
								            	<Col span={8}>
										            <FooterDrawer type='message' />
										        </Col>
								            	<Col span={8}>
										            <FooterDrawer type='note' />
										        </Col>
								            </Row>
							            </FooterCustomModule>
						            </Fixed>
								}
								</>
						)}
					</PageContent>
				)}
			</div>
			<SendBackModal open={showSendBackModal} handleOk={handleSendBack} handleCancel={() => setShowSendBackModal(false)} />
		</>
		
	)
}

export default KycLayout
