import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
import { uboBackDocsGet } from "../asyncThunks/uboBackDocsGet";
import { uboBackDocUrlGet } from "../asyncThunks/uboBackDocUrlGet";
import { CaseReducerType } from "../../store";

export type UboDocsResponse = Components.Schemas.BeneficialOwnerDocument;
export type UrlResponse = Paths.GetBeneficialOwnerDocument.Responses.$200;
export type ArchiveReponse = Paths.ArchiveBeneficialOwnerDocumentEndpoint.Responses.$200;

type State = {
    uboDocs: UboDocsResponse[];
    uboDocUrl: {id: string, value: UrlResponse}[];
};

const initialState: State = {
    uboDocs: [],
    uboDocUrl: []
};

type CaseReducers<State> = {
    uboBackDocsGetClear: CaseReducerType<State, object>;
    uboBackDocUrlGetClear: CaseReducerType<State, object>;
};

export const uboDocsSlice = createSlice<State, CaseReducers<State>>({
    name: "uboBackDocs",
    initialState,
    reducers: {
        uboBackDocUrlGetClear: (state, { payload }) => {
            state.uboDocUrl = initialState.uboDocUrl
        },
        uboBackDocsGetClear: (state, { payload }) => {
            state.uboDocs = initialState.uboDocs
        }
    },
    extraReducers: (builder) => {
        builder.addCase(uboBackDocsGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.uboDocs.findIndex((el) => el.id == action.meta.arg.params.beneficialOwnerId);
            if (elInd > -1) {
                //@ts-ignore
                state.uboDocs[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.uboDocs.push({id: action.meta.arg.params.beneficialOwnerId, value: action.payload})
            }
        });
        builder.addCase(uboBackDocUrlGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.uboDocUrl.findIndex((el) => el.id == action.meta.arg.params.beneficialOwnerId);
            if (elInd > -1) {
                //@ts-ignore
                state.uboDocUrl[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.uboDocUrl.push({id: action.meta.arg.params.beneficialOwnerId, value: action.payload})
            }
        });
    }
});

export const { uboBackDocUrlGetClear, uboBackDocsGetClear } = uboDocsSlice.actions;
export default uboDocsSlice.reducer;
