import * as Yup from 'yup'
import { FC, useEffect, useState } from 'react'
import _ from 'lodash';
import { Row, Col, Divider, Select, Card, Button, message, Drawer, Popover } from 'antd'
import Text, { TextProps } from 'antd/es/typography/Text'
import { DownOutlined, InboxOutlined, UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { countries } from 'countries-list'
import { dispatch } from '../../../store/store'
import { themeColor } from '../../../styles/themeStyles'
import { useSelector } from '../../../hooks/useSelector'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import FormInput from '../ui-elements/FormInput'
import FormSelect from '../ui-elements/FormSelect'
import FormDate from '../ui-elements/FormDate'
import FormUpload from '../ui-elements/FormUpload'
import Well from '../ui-elements/Well'
import {FormCheckboxRadio, FormCheckboxRadioStandalone} from '../ui-elements/FormCheckboxRadio'
import { individualDocsGetClear, individualDocUrlClear, individualDocCreateClear } from '../../../store/kyc-front/slices/individualDocsSlice'
import { individualBackDocsGetClear, individualBackDocUrlGetClear, individualBackDocCreateClear } from '../../../store/kyc-backend/slices/individualDocsSlice'
import { individualApprovedFieldsGet } from '../../../store/kyc-backend/asyncThunks/individualApprovedFieldsGet'
import BasicFormWrapper from '../ui-elements/BasicForm'
import { ButtonUpper } from '../ui-elements/Buttons'
import KycProfileFormModule from './KycProfileFormModule'
import KycDocsInfo from '../KycDocsInfo'
import { Components, Paths } from "../../../api/kyc/generated/client"

const CheckboxWrapper = styled.div`
    label {
        margin-bottom: 15px;
        line-height: 20px;
        gap: 25px;
        color: ${themeColor.gray}
    }
    input {
        position: relative;
        top: 3px;
    }
`

const Explanation = styled(Text)`
    font-size: 12px;
    color: ${themeColor.gray};
    display: block;
`
export type addressType = Components.Schemas.AddressEntry | undefined;
export type documentEntry = Components.Schemas.IdentityDocumentEntry | undefined;

interface KProps {
    individual: any,
    individualGet: any,
    individualUpdate?: any,
    individualClearUpdate?: any,
    printRender?: boolean,
    individualDocsGet?: any,
    individualDocCreate?: any,
    individualDocArchiveClear?: any,
    individualDocArchive?: any,
    docUrlGet?: any,
    validationAction?: any,
    validationActionClear?: any
}

const KycIndividualInfoForm: FC<KProps> = ({printRender, individual, individualDocsGet, individualDocCreate, individualDocArchive, individualDocArchiveClear, docUrlGet, individualGet, individualUpdate, individualClearUpdate, validationAction, validationActionClear}) => {
	const { Option } = Select;
    const envs = useSelector((state) => state.general.envs);
    const {kycApplication} = useSelector((state) => state.user2.kycApplication)
    const individualDocs = useSelector((state) => envs.admin ? state.admin.backIndividualDocs : state.user.individualDocs);
    const status = useSelector((state) => envs.admin ? state.admin.backStatus : state.user.status) 
    const [fileEl, setFileEl] = useState();
    const [uploading, setUploading] = useState(false);
    const [visible, setVisible] = useState(false);
    const person = kycApplication.values;

    const residentialAddress: addressType = person?.residentialAddress;
    const identityDocument: documentEntry = person?.identityDocument;
    let initialValues = {
        personalDetails: {
            firstName: person?.firstName || '',
            lastName: person?.lastName || '',
            dateOfBirth: person?.dateOfBirth || undefined,
            placeOfBirth: person?.placeOfBirth || '',
            residentialAddress: {
                address: residentialAddress?.address || '',
                postcode: residentialAddress?.postcode || '',
                city: residentialAddress?.city || '',
                country: residentialAddress?.country || ''
            },
            nationality: person?.nationality || '',
            occupation: person?.occupation || '',
            email: person?.email || '',
            phone: person?.phone || '',
            identityDocument: {
                kind: identityDocument?.kind || 'PassportKind',
                number: identityDocument?.number || '',
                placeOfIssue: identityDocument?.placeOfIssue || '',
                dateOfIssue: identityDocument?.dateOfIssue || undefined,
                dateOfExpiry: identityDocument?.dateOfExpiry || undefined,
                nationality: identityDocument?.nationality || ''
            },
            estimatedNetWorth: person?.estimatedNetWorth ? person?.estimatedNetWorth : '',
        },
        roleType: person?.roleType || undefined,
        sourceOfWealthBusinessActivities: person?.sourceOfWealthBusinessActivities || false,
        sourceOfWealthProfessionalOccupation: person?.sourceOfWealthProfessionalOccupation || false,
        sourceOfWealthInheritance: person?.sourceOfWealthInheritance || false,
        sourceOfWealthOther: person?.sourceOfWealthOther || false,
        sourceOfWealthDescription: person?.sourceOfWealthDescription || '',
        isPoliticallyExposedPerson: person?.isPoliticallyExposedPerson?.toString() || 'false',
        isUSNationality: person?.isUSNationality?.toString() || 'false'
    }
    const validation = Yup.object({
        personalDetails: Yup.object({      
            firstName: Yup.string(),
            lastName: Yup.string(),
            dateOfBirth: Yup.string(),
            placeOfBirth: Yup.string(),
            residentialAddress: Yup.object({
                address: Yup.string(),
                postcode: Yup.string(),
                city: Yup.string(),
                country: Yup.string()
            }),
            nationality: Yup.string(),
            occupation: Yup.string(),
            email: Yup.string().email('Please provide valid email address'),
            phone: Yup.string(),
            estimatedNetWorth: Yup.string(),
            identityDocument: Yup.object({
                kind: Yup.string(),
                number: Yup.string(),
                placeOfIssue: Yup.string(),
                dateOfIssue: Yup.string(),
                dateOfExpiry: Yup.string(),
                nationality: Yup.string()
            })
        }),
        roleType: Yup.string()
    });

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    useEffect(() => {
        if(envs.admin) {
            dispatch(individualApprovedFieldsGet({params: {individualId: envs.profileId}}))
        }
    }, [])
    const dateFormat = 'YYYY-MM-DD';
	return (
        <BasicFormWrapper 
            id='individualInfoForm' 
            initial={initialValues} 
            validation={validation} 
            dispatchSubmit={individualUpdate} 
            dispatchClear={individualClearUpdate} 
            params={{individualId: envs.profileId}} 
            dispatchGet={individualGet} 
            successCondition={''} 
            saveInnerForm={false} 
            deleteForm={false} 
            transformBools={true}
            validationAction={validationAction}  
        >
            {({handleInputChange, onSelectChange, onDateChange, setFieldValue, values, errors, disabled, initialErrorsValues}) => {
                return (
                    <>
                        <KycProfileFormModule 
                            type='individual'
                            id={individual.id}
                            person={person} 
                            personDocArchived={individualDocs.individualDocArchived}
                            personType={'individual'}
                            docs={individualDocs.individualDocs}
                            newDoc={individualDocs.newIndividualDoc}
                            docsGet={individualDocsGet}
                            docGetClear={envs.admin ? individualBackDocsGetClear :individualDocsGetClear}
                            docDelete={individualDocArchive}
                            docDeleteClear={individualDocArchiveClear}
                            docsGetParams={envs.admin && { individualId: envs.profileId}}
                            docCreate={individualDocCreate}
                            docClear={envs.admin ? individualBackDocCreateClear : individualDocCreateClear}
                            docUrlGet={docUrlGet} 
                            docUrlClear={envs.admin ? individualBackDocUrlGetClear : individualDocUrlClear}
                            docUrlResp={individualDocs.individualDocUrl}
                            values={values} 
                            errors={errors} 
                            handleInputChange={handleInputChange} 
                            onSelectChange={onSelectChange} 
                            onDateChange={onDateChange} 
                            setFieldValue={setFieldValue} 
                            printRender={printRender} 
                            disabled={disabled} 
                        />
                    </>
                )
            }}
        </BasicFormWrapper>
	)
}

export default KycIndividualInfoForm
