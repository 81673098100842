import { Divider, Timeline, Popconfirm, Form, Input } from 'antd'
import _ from 'lodash'
import { SolutionOutlined, CheckCircleFilled, CloseCircleFilled, QuestionCircleOutlined } from '@ant-design/icons'
import { FC, ReactNode, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { themeColor, themeSize } from 'styles/themeStyles'
import { useActionStatus } from 'hooks/useActionStatus'
import { dispatch } from 'store/store'
import { kycApplicationValidate } from 'store/kyc-front/asyncThunks/kycApplicationValidate'

import { useSelector } from 'hooks/useSelector'

import { ADMIN_BASE_PATH, BASE_PATH } from 'routes/AppRoutes'
import { setCurrentEditedForm, setEditedFormConfirmed } from 'store/kyc-front/slices/navSlice'

const { Item } = Timeline;


const CustomLinkSpan = styled.span`
    cursor: pointer;
    &.disabled-link {
      pointer-events: none;
      li {
        color: ${themeColor.grayLight};
      }
    }
    &.has-substeps {
        margin-left: 15px;
        font-style: italic;
    }
    &.substep {
        margin-left: 30px;
        font-style: italic;
    }
`
const KycStep = styled.span`
    font-size: 13px!important;
    .ant-timeline-item-content {
        top: -4px;
        margin-left: 20px;
    } 
    color: ${themeColor.gray};
    &.active {
        text-decoration: underline;
        color: #000;
        font-weight: bold;
    }
    
`

const IconBadge = styled.span`
    .anticon {
        font-size: ${themeSize.lg};
        left: 5px;
        position: relative;
        top: 1px;
    }
    &.success {
        color: ${themeColor.green};
    }
    &.error {
        color: ${themeColor.red};
    }
`

interface IProps {
    adminView?: boolean,
}

interface sProps {
    readyForReviewValidated: boolean | undefined
}

type StepSummary = {
    [key: number]: boolean;
  };

export const KycSteps: FC<IProps> = ({adminView}) => {
    const nav = useSelector((state) => state.general.nav);
    let { formUpdated } = nav;
    const envs = useSelector((state) => state.general.envs);
    const { validation, stepsScheme } = useSelector((state => state.user2.kycApplication))
    const [popupVisible, setPopupVisible] = useState(-1);
    const [stepSummary, setStepSummary] = useState<StepSummary>({0: false});
    const readyForReviewValidatedStatus = useActionStatus(kycApplicationValidate);
    useEffect(() => {
        if(!readyForReviewValidatedStatus.isFullfilled) {
            dispatch(kycApplicationValidate({ params: { id: envs.profileId} }));
        } 
    }, []);
    useEffect(() => {
        if(readyForReviewValidatedStatus.isFullfilled) {
            const stepStatus: StepSummary = {};
            //@ts-ignore
            validation?.validationErrors?.forEach(step => {
                //stepStatus[step.step] = step.validationErrors.length ? false : true;
            });
            setStepSummary(stepStatus);
        }
    }, [validation]);
    const handleVisibleChange = (i: number) => {
        if(formUpdated.updated) {
            setPopupVisible(i);
        }
    }
    const confirm = (link: string) => {
        dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: link, showErrors: false}));
    }
    const cancel = () => {
        setPopupVisible(-1);
    }
    let {step} = nav.nav;
    let el = useLocation();
    let apUboID = el.pathname.split('/')[envs.admin ? 4 : 3];
    return (
        <>
            <Timeline
                //@ts-ignore
                items={stepsScheme.map((stepItem, i) => {
                    let isActiveSubstep = stepItem.substep ? apUboID === stepItem.refId : false;
                    let isActiveSubstep2 = stepItem.substep ? apUboID === stepItem.refId ? stepItem.substep === nav.nav.substep ? true : false : false : false
                    let personHasErrors = false;
                    if(stepItem.hasSubsteps) {
                        personHasErrors = validation.find(err => err.refId === stepItem.refId && err.validationErrors.length) ? true : false
                    }
                    if(stepItem.substep && !isActiveSubstep) {
                        return undefined
                    }
                    const isActiveStep = stepItem.hasSubsteps ? false : stepItem.substep ? isActiveSubstep && isActiveSubstep2 ? true : false : stepItem.step === step ? true : false
                    const isDocStep = stepItem.fields && stepItem.fields[0] === 'docs.OtherDocumentKind'
                    const validationResult = validation[i]
                    const validationResultPrev = validation[i-1]
                    const stepPrev = stepsScheme[i-1]
                    let previousItem = null;
                    for (let i = 0; i < validation.length; i++) {
                        if (validation[i].link === stepItem.link) {
                            break;
                        }
                        previousItem = validation[i];
                    }
                    const showCheckIcon = isDocStep && (validationResultPrev?.validationErrors?.length || stepPrev.type ==='beneficialOwners') ? false : validationResult?.validationErrors?.length < 1 && i + 1 !== stepsScheme.length && !stepItem.type ? personHasErrors ? false : true : false
                    return {
                        color: isActiveStep ? themeColor.primaryColor : themeColor.gray,
                        children: (
                            <span key={'step'+ (i + 1)}>
                                {/*{formUpdated.updated || (!showCheckIcon && !isActiveStep) ? (
                                    <CustomLinkSpan className={stepItem.substep ? 'substep' : stepItem.hasSubsteps ? 'has-substeps' : ''} onClick={() => showCheckIcon || isActiveStep && confirm(stepItem.link)} key={'step'+ (i + 1)} >
                                        <KycStep className={isActiveStep ? 'active' : ''}>{stepItem.name}{showCheckIcon && <IconBadge style={{'position': 'absolute'}} className='success'><CheckCircleFilled /></IconBadge>}</KycStep>
                                    </CustomLinkSpan>

                                ) : (*/}
                                    <CustomLinkSpan className={stepItem.substep ? 'substep' : stepItem.hasSubsteps ? 'has-substeps' : ''} onClick={() => confirm(stepItem.link) } key={'step'+ (i + 1)} >
                                         <KycStep className={isActiveStep ? 'active' : ''}>{stepItem.name}{showCheckIcon && <IconBadge style={{'position': 'absolute'}} className='success'><CheckCircleFilled /></IconBadge>}</KycStep>
                                    </CustomLinkSpan>
                                {/*})}*/}
                            </span>
                        )
                    }
                })}
              />
        </>
    )
}