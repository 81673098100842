import { createSlice } from "@reduxjs/toolkit"
import { Components } from "api/kyc/generated/client2"
import { CaseReducerType } from "../../store"
import { clientsGet } from "../asyncThunks/clientsGet"

type State = {
    clientsList: Components.Schemas.ClientSummary[]
};


const initialState: State = {
    clientsList: []
};

type CaseReducers<State> = {
    clientsGetClear: CaseReducerType<State, object>;
};

export const clientsSlice = createSlice<State, CaseReducers<State>>({
  name: "clientsSlice",
  initialState,
  reducers: {
    clientsGetClear: (state, {payload}) => {
        state.clientsList = initialState.clientsList
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clientsGet.fulfilled, (state, action) => {
        state.clientsList = action.payload
    });
  },
});

export const { clientsGetClear } = clientsSlice.actions;
export default clientsSlice.reducer;
