import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
import { authorizedPersonDocsBackGet } from "../asyncThunks/authorizedPersonDocsBackGet";
import { authorizedPersonDocUrlBackGet } from "../asyncThunks/authorizedPersonDocUrlBackGet";
import { ObjectKeyType } from "types";
import { reduce } from "lodash";
import { CaseReducerType } from "../../store";

export type AuthorizedPersonDocsResponse = Components.Schemas.AuthorizedPersonDocument;
export type UrlResponse = Paths.GetAuthorizedPersonDocument.Responses.$200
export type ArchiveReponse = Paths.ArchiveAuthorizedPersonDocument.Responses.$200;
type ErrorResponse = {
    error: boolean,
    data: any
}

type State = {
    authorizedPersonDocs: AuthorizedPersonDocsResponse[];
    authorizedPersonDocUrl: {id: string, value: UrlResponse}[];
};

const initialState: State = {
    authorizedPersonDocs: [],
    authorizedPersonDocUrl: []
};

type CaseReducers<State> = {
    authorizedPersonDocUrlBackGetClear: CaseReducerType<State, object>;
    authorizedPersonDocsBackGetClear: CaseReducerType<State, object>;
};

export const authorizedPersonDocsSlice = createSlice<State, CaseReducers<State>>({
    name: "authorizedPersonBackDocs",
    initialState,
    reducers: {
        authorizedPersonDocUrlBackGetClear: (state, { payload }) => {
            state.authorizedPersonDocUrl = initialState.authorizedPersonDocUrl
        },
        authorizedPersonDocsBackGetClear: (state, { payload }) => {
            state.authorizedPersonDocs = initialState.authorizedPersonDocs
        }
    },
    extraReducers: (builder) => {
        builder.addCase(authorizedPersonDocsBackGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.authorizedPersonDocs.findIndex((el) => el.id == action.meta.arg.params.authorizedPersonId);
            if (elInd > -1) {
                //@ts-ignore
                state.authorizedPersonDocs[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.authorizedPersonDocs.push({id: action.meta.arg.params.authorizedPersonId, value: action.payload})
            }
        });
        builder.addCase(authorizedPersonDocUrlBackGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.authorizedPersonDocUrl.findIndex((el) => el.id == action.meta.arg.params.authorizedPersonId);
            if (elInd > -1) {
                //@ts-ignore
                state.authorizedPersonDocUrl[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.authorizedPersonDocUrl.push({id: action.meta.arg.params.authorizedPersonId, value: action.payload})
            }
        });
    },
});

export const { authorizedPersonDocUrlBackGetClear, authorizedPersonDocsBackGetClear } = authorizedPersonDocsSlice.actions;
export default authorizedPersonDocsSlice.reducer;
