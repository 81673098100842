import * as Yup from 'yup'
import { FC, useEffect, useState } from 'react'
import { moment } from '../ui-elements/FormDate'
import _ from 'lodash';
import { Row, Col, Divider, Select, Card, Button } from 'antd'
import Text, { TextProps } from 'antd/es/typography/Text'
import { countries } from 'countries-list'
import { dispatch } from '../../../store/store'
import { themeColor } from '../../../styles/themeStyles'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { APResponse } from '../../../store/kyc-front/slices/authorizedPersonsSlice'
import { authorizedPersonDocsGetClear, authorizedPersonDocUrlClear } from '../../../store/kyc-front/slices/authorizedPersonDocsSlice'
import { authorizedPersonDocsBackGetClear, authorizedPersonDocUrlBackGetClear } from '../../../store/kyc-backend/slices/authorizedPersonDocsSlice'
import { authorizedPersonsApprovedFieldsGet } from '../../../store/kyc-backend/asyncThunks/authorizedPersonsApprovedFieldsGet'
import { authorizedPersonsApprovedFieldsGetClear } from '../../../store/kyc-backend/slices/approvedSlice'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import FormInput from '../ui-elements/FormInput'
import FormSelect from '../ui-elements/FormSelect'
import FormDate from '../ui-elements/FormDate'
import FormUpload from '../ui-elements/FormUpload'
import Well from '../ui-elements/Well'
import { FormCheckboxRadio } from '../ui-elements/FormCheckboxRadio'
import BasicFormWrapper from '../ui-elements/BasicForm'
import KycProfileFormModule from './KycProfileFormModule'
import { ButtonUpper } from '../ui-elements/Buttons'
import KycDocsInfo from '../KycDocsInfo'
import { Components, Paths } from "../../../api/kyc/generated/client";

const AlignedCol = styled(Col)`
    align-self: flex-end;
`

interface KProps {
    person: APResponse,
    printRender?: boolean,
    authorizedPersonsGet?: (props: any) => any,
    authorizedPersonUpdate?: (props: any) => any,
    authorizedPersonDocsGet: (props: any) => any,
    authorizedPersonDocCreate?: (props: any) => any,
    authorizedPersonDocCreateClear?: (props: any) => any,
    authorizedPersonArchive?: (props: any) => any,
    authorizedPersonClearUpdate?: (props: any) => any,
    authorizedPersonClearArchive?: (props: any) => any,
    authorizedPersonDocArchiveClear?: (props: any) => any,
    authorizedPersonDocArchive?: (props: any) => any,
    docUrlGet?: any,
    deleteForm?: boolean,
    restoreForm?: boolean,
    handleDelete?: () => void,
    validationAction?: any,
    validationActionClear?: any
}

type apUpdate = Components.Schemas.UpdateAuthorizedPerson | undefined;
type addressType = Components.Schemas.AddressEntry | undefined;
type documentEntry = Components.Schemas.IdentityDocumentEntry | undefined;


const KycAuthorizedPersonsForm: FC<KProps> = ({person, printRender, authorizedPersonUpdate, authorizedPersonsGet, authorizedPersonDocsGet, authorizedPersonDocArchiveClear, docUrlGet, deleteForm, restoreForm, authorizedPersonDocCreate, authorizedPersonDocCreateClear, authorizedPersonArchive, authorizedPersonClearUpdate, authorizedPersonClearArchive, authorizedPersonDocArchive, validationAction, validationActionClear}) => {
	const { Option } = Select;
    const [initialErrors, setInitialErrors] = useState({});
    const [errorsLoaded, setErrorsLoaded] = useState(false);
    const [docsListing, setDocsListing] = useState({});
    const envs = useSelector((state) => state.general.envs);
    const status = useSelector((state) => envs.admin ? state.admin.backStatus : state.user.status) 
    const authorizedPersons = useSelector((state) => envs.admin ? state.admin.backAuthorizedPersons : state.user.authorizedPersons);
    const authorizedPersonDocs = useSelector((state) => envs.admin ? state.admin.backAuthorizedPersonDocs : state.user.authorizedPersonDocs);
    const [fileEl, setFileEl] = useState();
    const [uploading, setUploading] = useState(false);
    const residentialAddress: addressType = person.residentialAddress;
    const identityDocument: documentEntry = person.identityDocument;
    useEffect(() => {
        if(envs.admin) {
            dispatch(authorizedPersonsApprovedFieldsGet({params: {companyId: envs.profileId, authorizedPersonId: person.authorizedPersonId}}))
        }
        //dispatch(authorizedPersonDocsGet({ params: { companyId: envs.profileId, authorizedPersonId: person.authorizedPersonId } }))
    }, []);
    let initialValues: apUpdate = { 
        personalDetails: {
            firstName: person.firstName || '',
            lastName: person.lastName || '',
            dateOfBirth: person.dateOfBirth || undefined,
            placeOfBirth: person.placeOfBirth || '',
            residentialAddress: {
                address: residentialAddress?.address || '',
                postcode: residentialAddress?.postcode || '',
                city: residentialAddress?.city || '',
                country: residentialAddress?.country || ''
            },
            nationality: person.nationality || '',
            occupation: person.occupation || '',
            email: person.email || '',
            phone: person.phone || '',
            identityDocument: {
                kind: identityDocument?.kind || 'PassportKind',
                number: identityDocument?.number || '',
                placeOfIssue: identityDocument?.placeOfIssue || '',
                dateOfIssue: identityDocument?.dateOfIssue || undefined,
                dateOfExpiry: identityDocument?.dateOfExpiry || undefined,
                nationality: identityDocument?.nationality || ''
            }
        },
        roleType: person.roleType,
    }
    useEffect(() => {
        /*let apValidation = status.updatedStatus.authorizedPersonsValidated;
        let errors = {};
        if(apValidation && errorsLoaded === false) {
            apValidation.forEach((error, i) => {
                if(!_.isEmpty(error.validationErrors) && (error.entry === person.authorizedPersonId)) {
                    //@ts-ignore
                    errors[error.validationErrors?.EmptyField?.name] = 'Field is required'
                }
            })
            setInitialErrors(errors);
            setErrorsLoaded(true);
           
        }*/
        
    })
    const validation = Yup.object({
        personalDetails: Yup.object({      
            firstName: Yup.string(),
            lastName: Yup.string(),
            dateOfBirth: Yup.string(),
            placeOfBirth: Yup.string(),
            residentialAddress: Yup.object({
                address: Yup.string(),
                postcode: Yup.string(),
                city: Yup.string(),
                country: Yup.string()
            }),
            nationality: Yup.string(),
            occupation: Yup.string(),
            email: Yup.string().email('Please provide valid email address'),
            phone: Yup.string(),
            identityDocument: Yup.object({
                kind: Yup.string(),
                number: Yup.string(),
                placeOfIssue: Yup.string(),
                dateOfIssue: Yup.string(),
                dateOfExpiry: Yup.string(),
                nationality: Yup.string()
            })
        }),
        roleType: Yup.string(),
    });
    const dateFormat = 'YYYY-MM-DD';
	return (
        <BasicFormWrapper 
            id={person.authorizedPersonId} 
            type='authorized-persons'
            initial={initialValues} 
            initialErrors={initialErrors} 
            validation={validation} 
            dispatchSubmit={authorizedPersonUpdate} 
            params={{companyId: envs.profileId, authorizedPersonId: person.authorizedPersonId}} 
            dispatchClear={authorizedPersonClearUpdate} 
            dispatchGet={authorizedPersonsGet} 
            successCondition={authorizedPersons.updatedAuthorizedPerson.authorizedPersonId === person.authorizedPersonId ? 'success' : ''} 
            saveInnerForm={true} 
            deleteForm={deleteForm === false ? false : true} 
            restoreForm={restoreForm}
            saveText={'Save'} 
            printRender={printRender}
            validationAction={validationAction} 
        >
            {({handleInputChange, onSelectChange, onDateChange, setFieldValue, values, errors, disabled, initialErrorsValues}) => {
                return (
                    <KycProfileFormModule 
                        person={person} 
                        type='ap'
                        personType={'authorized-persons'}
                        //@ts-ignore
                        docs={authorizedPersonDocs.authorizedPersonDocs.find(doc => doc.id === person.authorizedPersonId)}
                        //@ts-ignore
                        newDoc={authorizedPersonDocs.newAuthorizedPersonDoc}
                        docsGet={authorizedPersonDocsGet}
                        docGetClear={envs.admin ? authorizedPersonDocsBackGetClear : authorizedPersonDocsGetClear}
                        docsGetParams={{ companyId: envs.profileId, authorizedPersonId: person.authorizedPersonId }}
                        docCreate={authorizedPersonDocCreate}
                        docClear={authorizedPersonDocCreateClear}
                        docUrlGet={docUrlGet} 
                        docUrlClear={envs.admin ? authorizedPersonDocUrlBackGetClear : authorizedPersonDocUrlClear}
                        docUrlResp={authorizedPersonDocs.authorizedPersonDocUrl.find(url => url.id === person.authorizedPersonId)}
                        docDelete={authorizedPersonDocArchive}
                        docDeleteClear={authorizedPersonDocArchiveClear}
                        values={values} 
                        errors={errors} 
                        showRoleType={true} 
                        handleInputChange={handleInputChange} 
                        onSelectChange={onSelectChange} 
                        onDateChange={onDateChange} 
                        setFieldValue={setFieldValue} 
                        disabled={disabled} 
                        printRender={printRender} 
                    />
                )
            }}
        </BasicFormWrapper>
	)
}

export default KycAuthorizedPersonsForm
