import { createSlice } from "@reduxjs/toolkit";
import { Components } from "../../../api/kyc-backend/generated/client";
import { companyBackValidate } from "../asyncThunks/companyBackValidate";
import { readyForReviewBackValidate } from "../asyncThunks/readyForReviewBackValidate";
import { individualBackReadyForReviewValidate } from "../asyncThunks/individualBackReadyForReviewValidate";
import { CaseReducerType } from "../../store";

export type IndividualDeclarationsValidateResponse = Components.Schemas.ValidatedModelEntryIndividualId;
export type IndividualValidateResponse = Components.Schemas.ValidatedModelEntryIndividualId;
export type ProfileResponse = Components.Schemas.ValidatedModelEntryCompanyId;
export type AuthorizedPersonsResponse = Components.Schemas.ValidatedModelEntryAuthorizedPersonId[];
export type UbosResponse = Components.Schemas.ValidatedModelEntryBeneficialOwnerId[];
export type ReadyForReviewResponse = Components.Schemas.CompanyPreReviewValidated;
export type IndividualReadyForReviewResponse = Components.Schemas.IndividualPreReviewValidated;

type State = {
  companyValidated: ProfileResponse,
  readyForReviewValidated: ReadyForReviewResponse,
  individualReadyForReviewValidated: IndividualReadyForReviewResponse
};

const initialState: State = {
  companyValidated: {
    entry: '',
    validationErrors: []
  },
  readyForReviewValidated: {
    // @ts-ignore
    companyProfileValidated: {
      entry: '',
      validationErrors: []
    },
    numberOfDirectorsValidated: {
      entry: 0
    },
    numberOfSignatoryValidated: {
      entry: 0
    },
    numberOfBeneficialOwnersValidated: {
      entry: 0
    },
    percentageFilled: 0
  },
  individualReadyForReviewValidated : {
    // @ts-ignore
    beneficialOwnerValidated: {},
    percentageFilled: 0
  }
};

type CaseReducers<State> = {
  companyBackValidateClear: CaseReducerType<State, object>;
  readyForReviewBackValidateClear: CaseReducerType<State, object>;
  individualBackReadyForReviewValidateClear: CaseReducerType<State, object>;
};

export const backStatusSlice = createSlice<State, CaseReducers<State>>({
  name: "backStatus",
  initialState,
  reducers: {
    companyBackValidateClear: (state, { payload }) => {
      state.companyValidated = initialState.companyValidated
    },
    readyForReviewBackValidateClear: (state, { payload }) => {
      state.readyForReviewValidated = initialState.readyForReviewValidated
    },
    individualBackReadyForReviewValidateClear: (state, { payload }) => {
      state.individualReadyForReviewValidated = initialState.individualReadyForReviewValidated
    }
  },
  extraReducers: (builder) => {
    builder.addCase(companyBackValidate.fulfilled, (state, action) => {
      state.companyValidated = action.payload
    });
    builder.addCase(readyForReviewBackValidate.fulfilled, (state,action) => {
      state.readyForReviewValidated = action.payload
    });
    builder.addCase(individualBackReadyForReviewValidate.fulfilled, (state,action) => {
      state.individualReadyForReviewValidated = action.payload
    })
  },
});

export const { companyBackValidateClear, readyForReviewBackValidateClear, individualBackReadyForReviewValidateClear } = backStatusSlice.actions;
export default backStatusSlice.reducer;
